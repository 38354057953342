var Login=
{
    run_once:false,
	init:function()
	{
        jQuery('.user-login-box').on("click","#lnk-login",function(e){
            Login.show_login_box();


        });
        jQuery('.view-container').on("click", 'a.lnk-login',function(e){
            Login.show_login_box();
        });
        jQuery(".lnk-login-home").click(function(e){
                    Login.show_login_box();


                });
        jQuery(".lnk-login-home2").click(function(e){
            Login.show_login_box();


        });
        jQuery('.sign_in_sign_up').click(function(e){
           Login.show_login_box();
        });
	},
    show_login_box:function(){
        jQuery('.alert-error').remove();
      //  jQuery('#topnavbar').addClass('in');
                    jQuery('#user_login_dialog').modal({
                                    //  keyboard: false
                                });
        $('#user_login_dialog').on('hide.bs.modal', function (e) {
           // jQuery('#topnavbar').removeClass('in');
        })
                    var dlg=jQuery('#user_login_dialog');
                    if (!Login.run_once){
                    jQuery('#password',dlg).bind('keypress', Login.enter_pressed);
                    jQuery('#login',dlg).bind('keypress', Login.enter_pressed);
                    jQuery('#remember_me',dlg).bind('keypress', Login.enter_pressed);
                    jQuery('#login').focus();
                    jQuery('a.login').click(Login.login);
                        jQuery('#user_email',dlg).bind('keypress', Login.enter_pressed_signup);
                         jQuery('#user_password',dlg).bind('keypress', Login.enter_pressed_signup);
                        jQuery('#user_password_confirmation',dlg).bind('keypress', Login.enter_pressed_signup);

                    jQuery('#frm_signup').submit(Login.signup);
                    jQuery('a.signup').click(function(e){
                        Login.signup(e);
                        jQuery('#topnavbar').removeClass('in');
                    });
                        Login.run_once=true;
                    }
        $('.modal-backdrop').remove();
    },
    enter_pressed:function(e) {
                	if(e.keyCode==13){
                		Login.login(e);
                	}
                },
    enter_pressed_signup:function(e) {
                    	if(e.keyCode==13){
                    		Login.signup(e);
                    	}
                    },
    signup:function(e){

        jQuery('.alert-error').remove();
        var email=jQuery('#user_email_reg','#ajax-user-signup-form').val();
        var p=jQuery('#user_password','#ajax-user-signup-form').val();
        var pc=jQuery('#user_password_confirmation','#ajax-user-signup-form').val();
        var message="";
        var valid=true;
                if (email==""){
                    jQuery('#user_email_reg','#ajax-user-signup-form').after('<div class="alert alert-error">Please enter an email address</div>');
                    valid=false;
                }
                if (p==""){
                    jQuery('#user_password','#ajax-user-signup-form').after('<div class="alert alert-error">Please enter a password</div>');
                    valid=false;
                }
                if (p.length>0 && p.length<4){
                            jQuery('#user_password','#ajax-user-signup-form').after('<div class="alert alert-error">Password must be 4 characters</div>');
                            valid=false;
                        }
                else if (p!=pc){
                    jQuery('#user_password_confirmation','#ajax-user-signup-form').after('<div class="alert alert-error">Passwords do not match</div>');
                    valid=false;
                }
                if (valid){
                    jQuery('a.signup').button('loading');
                   /* try{
                        validateoffers();
                    }
                    catch (e){
                        if (typeof console=="undefined"){

                        }
                        else {
                            console.log(e.message);
                        }
                    }*/
                    var script = document.createElement('script');
                    script.onload = function () {
                    grecaptcha.enterprise.ready(function() {
                        grecaptcha.enterprise.execute('6LcgXrQlAAAAAC2H213o2fkTLF52wruER2HUsS80', {action:'db_create_user'})
                                                              .then(function(token) {
                                                        // add token value to form
                                                      //  document.getElementById('g-recaptcha-response').value = token;
                                                        var authtoken = $('meta[name=csrf-token]').attr('content');
                                                                            jQuery.ajax({
                                                                                    url:'/users/create_ajax',
                                                                                    type:"POST",
                                                                                    headers: {
                                                                                        'X-CSRF-Token': authtoken
                                                                                    },
                                                                                    dataType:'text',
                                                                                    data:{"recaptcha":token,
                                                                                    "user[email]":email,"user[password]":p,"user[password_confirmation]":pc,
                                                                                    quarterly:$('#quarterly_newsletter').is(":checked"),
                                                                                    weekly:$('#weekly_newsletter').is(":checked")},
                                                                                    success:function (data) {
                                                                                        //location.reload();
                                                                                        jQuery('#topnavbar').removeClass('in');
                                                                                        whalewisdom.Utilities.show_alert("Signup Request Received", "Thanks for signing up! Please check your email to activate your account before logging in.");
                                                                                         $("#user_login_dialog").modal('hide');
                                                                                       // window.location="/";
                                                                                    },
                                                                                    error:function (d) {
                                                                                       // whalewisdom.Utilities.wait_dialog.hide();
                                                                                        whalewisdom.Utilities.show_alert("Signup Failure", d.responseText);
                                                                                        jQuery('a.signup').button('reset');
                                                                                    }
                                                                                });
                                                    });
                                                    })
                    };
                    script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LcgXrQlAAAAAC2H213o2fkTLF52wruER2HUsS80";

                    document.head.appendChild(script);


                }
        e.preventDefault();
        return false;
    },
    login:function(e){
        jQuery('.alert-error').remove();
        var login_name=jQuery('#login',jQuery('#user_login_dialog')).val();
        var p=jQuery('#password',jQuery('#user_login_dialog')).val();
        var remember=jQuery('#remember_me',jQuery('#user_login_dialog'));
        remember=remember.is(':checked');
        var valid=true;
        if (login_name==""){
            jQuery('#login').after('<div class="alert alert-error">Please enter your email address or login name</div>');
            valid=false;
        }
        if (p==""){
                    jQuery('#password').after('<div class="alert alert-error">Please enter your password</div>');
            valid=false;
                }
        if (!valid){
            e.preventDefault();
            return false;
        }
        jQuery('a.login').button('loading');
        var script = document.createElement('script');
                            script.onload = function () {
                            grecaptcha.enterprise.ready(function() {
                                grecaptcha.enterprise.execute('6LcgXrQlAAAAAC2H213o2fkTLF52wruER2HUsS80', {action:'db_login'})
                                                                      .then(function(token) {
                   var csrftoken = $('meta[name=csrf-token]').attr('content');
                           jQuery.ajax({
                                       url:'/sessions/dashboard_login',
                                       dataType:'text',
                               headers: {
                               //    'Content-Type': 'application/json',
                                   'X-CSRF-Token': csrftoken
                               },
                               type:"POST",
                                       data:{"login":login_name,password:p,remember_me:remember,recaptcha:token},
                                       success:function (data) {
                                           location.reload();
                                       },
                                       error:function (d) {
                                          // whalewisdom.Utilities.wait_dialog.hide();
                                           jQuery('a.login').button('reset');
                                           whalewisdom.Utilities.show_alert("Login Failure", d.responseText);
                                       }
                                   });
                                                            });
                                                            })
                            };
                            script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LcgXrQlAAAAAC2H213o2fkTLF52wruER2HUsS80";

                            document.head.appendChild(script);

    }
};
jQuery(Login.init);
