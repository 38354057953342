var Search=
{
	stock_search_table:null,
	filer_search_table:null,
	init:function()
	{
		//var cookies=require("jaaulde-cookies");
		var s=Cookies.get('search_filter');
		if (s){
			$('#search-filer-restrictions').val(s);
			$('#search-filer-restrictions-top').val(s);
		}
		$('#search-filer-restrictions').ddslick({
			background:'transparent',
			width:"100%",
		    onSelected: function(selectedData){
				Cookies.set('search_filter', selectedData.selectedData.value);
		        //callback function: do something with selectedData;
		    }
		});
		$('#search-filer-restrictions-top').ddslick({
					background:'transparent',
					width:115,
				    onSelected: function(selectedData){
						Cookies.set('search_filter', selectedData.selectedData.value);
				        //callback function: do something with selectedData;
				    }
				});
		/*var loader = new YAHOO.util.YUILoader({
	        //Get these modules
	        require: ['datatable','datasource','autocomplete',
                    'animation','paginator','event','button'],
	        allowRollup: true,
                loadOptional:true,
                //combine:true,https://dl.dropboxusercontent.com/u/40036711/Scripts/jquery.ddslick.js
	        onSuccess: function() 
			{*/
           // YAHOO.util.Event.addListener("stock_symbol",'keypress',Search.stock_search);
           
				//new YAHOO.widget.Button("cmd_stock_search", {onclick: {fn: Search.stock_search}});
				//new YAHOO.widget.Button("cmd_filer_search", {onclick: {fn: Search.filer_search}});
                jQuery('#cmd_stock_filer_search').click(Search.filer_stock_search);
                jQuery(".ac_filer_stock_name").keypress(function(e) {
                  if (e.keyCode == 13) {

                     e.preventDefault();
               //       if( jQuery('#mobile-view-header').is(':visible') ) {
                          Search.filer_stock_search();

                 //     }

                      //Search.filer_stock_search();
                   }

                });
				//YAHOO.util.Event.addListener(jQuery('#cmd_stock_lookup'), "click", Search.show_stock_lookup);
                try {
                    Search.setup_autocomplete();
                }
        catch(err){
            console.error(err);
        }
		
	},
    filer_stock_search:function(){
        var params=jQuery('.ac_filer_stock_name:visible').val();
		if (params.length<1){
			params=$('#ac_filer_stock_name').val();
		}
        if (params.length<1){
            whalewisdom.Utilities.show_alert("Incomplete Search Parameters","Please enter something in the search box.");
            return;
        }
        whalewisdom.Utilities.toggle_wait(true);
		whalewisdom.Utilities.show_wait_dialog('Please Wait','Searching for stock...',true,Search.cancel_stock_search);
        if (params.length==0)
            return;
          Search.stock_search_ajax=jQuery.ajax({
                type:'GET',
                url:'/search/filer_stock_search2',
                dataType:"json",
                data:"search_phrase="+encodeURIComponent(params)+'&filer_restrictions='+jQuery('.search-filer-restrictions').val(),
                success:function(data){
                    var table='<table class="table table-bordered table-striped table-condensed">';//</table>';
                    for (var i=0, array_length=data.length;i<array_length;i++){
                        table+='<tr><td>'+data[i].rectype+'</td><td><a href="/'+data[i].rectype+'/' +data[i].permalink+'">'+
                            data[i].name+'</a></td><td>'+data[i].other_information+'</td></tr>';
                    }
                    table+="</table>";
                    whalewisdom.Utilities.toggle_wait(false);
		            whalewisdom.Utilities.wait_dialog.hide();
                    /*jQuery('<div id="search_results_panel"></div>').
                        html(table).
                        dialog({modal:true,autoOpen:true,position: 'center',width:430, height:400,resizable:true,show:'slide',
                        title:"Search Results"});   */
                    jQuery('<div class="modal"><div class="modal-dialog"> <div class="modal-content"> '+
                             ' <div class="modal-header">'+
                                '<button type="button" class="close" data-dismiss="modal">×</button>'+
                                '<h3>Search Results</h3>'+
                              '</div>'+
                              '<div class="modal-body">'+
                                '<p>'+table+'</p>'+
                              '</div>'+
                              '<div class="modal-footer">'+
                               ' <a href="#" class="btn btn-info" data-dismiss="modal">Close</a>'+
                              '</div></div></div>'+
                           ' </div>').modal({backdrop:false});
                },
                error:function(jqXHR, textStatus, errorThrown){
                    whalewisdom.Utilities.toggle_wait(false);
		            whalewisdom.Utilities.wait_dialog.hide();
                    whalewisdom.Utilities.show_alert("There was an error in your search:",jqXHR.responseText);
                }
            });
    },
    setup_autocomplete:function(){
      //  jQuery("input.ac_filer_stock_name").autocomplete({
        jQuery("#ac_filer_stock_name_top").autocomplete({
            minLength:1,
			//html:true,
            source:function(request, response) {
				var v=$(this.element);
				var sel=$('#'+v.data('select-id'));
                jQuery.getJSON("/search/filer_stock_autocomplete2",
                    { term: request.term,filer_restrictions: sel.find('.dd-selected-value').val() },
                          response);
            },
            select: function( event, ui ) {
                if (ui.item.rectype==="filer"){
                   window.location.href="/filer/"+ui.item.permalink;
                   jQuery("input.ac_filer_stock_name:visible").val(ui.item.value);
                }
                else if (ui.item.rectype==="stock"){
                    window.location.href="/stock/"+ui.item.permalink;
                    jQuery("input.ac_filer_stock_name:visible").val(ui.item.value);
                }

				return false;
			}
        }).data( "ui-autocomplete" )._renderItem = function( ul, item ) {
			return jQuery( "<li></li>" )
				.data( "item.autocomplete", item )
				.append( jQuery("<a></a>").html(item.label))
				.appendTo( ul );
		};

		if (jQuery("#ac_filer_stock_name").length>0) {
			jQuery("#ac_filer_stock_name").autocomplete({
				minLength: 1,
				source: function (request, response) {
					var v=$(this.element);
									var sel=$('#'+v.data('select-id'));
					jQuery.getJSON("/search/filer_stock_autocomplete2",
						{term: request.term, filer_restrictions: sel.find('.dd-selected-value').val()},
						response);
				},
				select: function (event, ui) {
					if (ui.item.rectype === "filer") {
						window.location.href = "/filer/" + ui.item.permalink;
						jQuery("input.ac_filer_stock_name:visible").val(ui.item.value);
					}
					else if (ui.item.rectype === "stock") {
						window.location.href = "/stock/" + ui.item.permalink;
						jQuery("input.ac_filer_stock_name:visible").val(ui.item.value);
					}

					return false;
				}
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				return jQuery("<li></li>")
					.data("item.autocomplete", item)
					.append(jQuery("<a></a>").html(item.label))
					.appendTo(ul);
			};

			jQuery["ui"]["autocomplete"].prototype["_renderItem"] = function (ul, item) {
				return jQuery("<li></li>")
					.data("item.autocomplete", item)
					.append(jQuery("<a></a>").html(item.label))
					.appendTo(ul);
			};
		}
    },
    cancel_stock_search:function(e)
	{
		try
		{
            Search.stock_search_ajax.abort();
			//Search.stock_search_ajax.abort();
                        //YAHOO.util.Connect.abort(Search.stock_search_ajax);
		}
		catch(e)
		{
			alert(e);
		}

		whalewisdom.Utilities.toggle_wait(false);
		whalewisdom.Utilities.wait_dialog.hide();
	},

	show_stock_lookup:function()
	{
		Search.stock_dialog = new YAHOO.widget.Panel("stock_dialog",
			 {
			 close:true,
			 visible:false,
			 draggable:true,
			 constraintoviewport:true,
			 fixedcenter:true,
			 modal:true/*,
                         effect:{effect:YAHOO.widget.ContainerEffect.SLIDE,duration:0.25}*/
			 }
		 );
		 Search.stock_dialog.setHeader('Stock symbol lookup');
		 Search.stock_dialog.setBody("<form onsubmit=\"Search.stock_lookup();return false;\"><input type=\"text\" id=\"company_name\"\\><input type=\"button\" value=\"Lookup\" onclick=\"Search.stock_lookup();\"\\><\/form>");
		 Search.stock_dialog.setFooter('');
		 Search.stock_dialog.render(document.body);
		 Search.stock_dialog.show();
	},
	stock_lookup:function()
	{
		var params="company_name="+escape(jQuery('#company_name').val());
                jQuery.ajax({
                    url:'/search/stock_lookup?'+params,
                    success:Search.stock_lookup_success,
                    error:function(){
                        Search.stock_dialog.hide();
                    }
                });
		//new Ajax.Request('/search/stock_lookup?'+params,
		//	{onSuccess:Search.stock_lookup_success,asynchronous:true, evalScripts:true});
		
	},
	stock_lookup_success:function(o)
	{
		Search.stock_dialog.hide();
		/*Search.stock_dialog = new YAHOO.widget.Panel("stock_dialog",
			 {
			 close:true,
			 visible:false,
			 draggable:true,
			 constraintoviewport:true,
			 fixedcenter:true,
			 modal:true
			 }
		 );
		 Search.stock_dialog.setHeader('Matching stocks');
		 Search.stock_dialog.setBody(o);
		 Search.stock_dialog.setFooter('');
		 Search.stock_dialog.render(document.body);
		 Search.stock_dialog.show();   */
        jQuery(o).dialog({ minWidth: 400 });
	},
	replace_symbol:function(symbol)
	{
		jQuery('#stock_symbol').val(symbol);
		Search.stock_dialog.hide();
	}
}
jQuery(function () {
    Search.init();
});
//YAHOO.util.Event.addListener(window, "load",Search.init);
