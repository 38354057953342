if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }

window.whalewisdom.Ownership=
{
	calendar:null,
	min_date:null,
	max_date:null,
	page_date:null,
	current_date:null,
	init:function()
	{
            if ($('#insider_buying').length==0 && jQuery('#issuer_view_contents').length==0){
                return;
            }
            if (jQuery('#index_contents').length>0){
                var url=window.location.href;
                if (url.indexOf("index")>0)
                    url=url.replace("index","index_cache");
                else
                    url=url+"/index_cache";
                if (window.location.href.indexOf("index_by_day")) {
               /*      Ownership.calendar = new YAHOO.widget.Calendar("calendar","calendar_container",
                                { pagedate : Ownership.page_date,
                                  mindate: Ownership.min_date,
                                  maxdate: Ownership.max_date }
                                );

				Ownership.calendar.select(Ownership.current_date);
				Ownership.calendar.selectEvent.subscribe(Ownership.calendar_select_event,Ownership.calendar, true);
				Ownership.calendar.render();*/
                    jQuery('#calendar_container').datepicker({onSelect:function(dateText,inst){
                                    		            var selDate=new Date(dateText);
                        var u="/ownership/index/";
                                if (window.location.href.indexOf("index_by_day"))
                                    u="/ownership/index_by_day/";
                        		window.location=u+selDate.getFullYear()+"/"+(selDate.getMonth()+1)+"/"+selDate.getDate();
                                    		          //  window.location="/schedule13d/index/"+selDate.getFullYear()+"/"+(selDate.getMonth()+1)+"/"+selDate.getDate();
                                    		        }});

                                          //  jQuery('#calendar_container').datepicker('show');
                                            jQuery('#calendar_container').datepicker('setDate',whalewisdom.Ownership.current_date);
                }
                else{


                jQuery('#index_contents').load(url,function(){
                    Ownership.calendar = new YAHOO.widget.Calendar("calendar","calendar_container",
                                { pagedate : Ownership.page_date,
                                  mindate: Ownership.min_date,
                                  maxdate: Ownership.max_date }
                                );

				Ownership.calendar.select(Ownership.current_date);
				Ownership.calendar.selectEvent.subscribe(Ownership.calendar_select_event,Ownership.calendar, true);
				Ownership.calendar.render();
                });
                    }
               /* jQuery.ajax({
                    url:window.location.href.replace("index","")+"/index_cache",
                    success:function(data){
                        jQuery('#index_contents').html(data);
                        
                    }
                });*/

            }
            else if (jQuery('#issuer_view_contents').length>0){
               /* jQuery.ajax({
                    url:window.location.href.replace("issuer_view","issuer_view_cache"),
                    dataType:"html",
                    success:function(data){
                        jQuery('#issuer_view_contents').html(data);
                    }
                });*/
            }
			/*}});
		loader.insert();*/
		
	},
	calendar_select_event:function(type,args,obj)
	{
		var selected = args[0]; 
		var selDate = this.toDate(selected[0]);
        var u="/ownership/index/";
        if (window.location.href.indexOf("index_by_day"))
            u="/ownership/index_by_day/";
		window.location=u+selDate.getFullYear()+"/"+(selDate.getMonth()+1)+"/"+selDate.getDate();
	}
};

jQuery(function () {
    whalewisdom.Ownership.init();
});