var Statistics=
{
	init:function()
	{
		/*var loader = new YAHOO.util.YUILoader({
	        //Get these modules
	        require: ['tabview'],
	     //   allowRollup: true,
                loadOptional:true,
             //   combine:true,
	        onSuccess: function() 
			{*/
                            if (jQuery('#stats').length>0){
                               //  jQuery('#statistics_tab').tab('show');
                                //Statistics.tabview = new YAHOO.widget.TabView('statistics_tab');
				jQuery('#stats').css('display','block');
                            }
			/*}});
		loader.insert();*/
	}
};
jQuery(function () {
    Statistics.init();
});