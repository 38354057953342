window._dcq = window._dcq || [];
window._dcs = window._dcs || {};
window._dcs.account = '4432682';

(function () {
    var dc = document.createElement('script');
    dc.type = 'text/javascript';
    dc.async = true;
    dc.src = '//tag.getdrip.com/4432682.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(dc, s);
})();
if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.Drip = {
    init: function () {
        $(document).on("login-box-loaded", {
            foo: "bar"
        }, function (event, email, subscriber) {
            $("input[name='fields[email]']").val(email);
            if (typeof _dcq != "undefined") {
                /* if (subscriber && subscriber > 0) {
                 _dcq.push(["hideForm", {id: "8376981"}]);
                 _dcq.push(["hideForm", {id: "6741528"}]);
                 _dcq.push(["hideForm", {id: "9702"}]);
                 }
                 else{*/
                whalewisdom.Drip.check_for_qns();
                // }
            }
            setTimeout(whalewisdom.Drip.check_for_qns, 2000);
            //fields[email]
            //console.log( event.data.foo ); // "bar"
        });
        whalewisdom.Drip.check_for_qns();
    },
    check_for_qns: function () {
        var i = $('#qns').val();
        if (i && i == "1") {
            _dcq.push(["hideForm", {id: "8376981"}]);
            _dcq.push(["hideForm", {id: "6741528"}]);
            _dcq.push(["hideForm", {id: "9702"}]);
        }
    }
}

jQuery(whalewisdom.Drip.init);


