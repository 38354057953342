whalewisdom.HoldingsSearch=
{
    stock_match_dlg:null,
    results_table:null,
    stock_id:0,
  /*  init:function(){
       // ("head").append($("<link rel='stylesheet' href='style.css' type='text/css' media='screen' />"));
        jQuery.getScript('/javascripts/jquery/datatables/js/jquery.dataTables.min.js',
            whalewisdom.HoldingsSearch.load);
    },*/
    init:function(){
        if (jQuery("#txt_adv_search_stock_symbol").length==0){
            return;
        }
        //jQuery.noConflict();
        whalewisdom.HoldingsSearch.reset_parameters();
        whalewisdom.HoldingsSearch.bind_listeners();
       // whalewisdom.HoldingsSearch.refresh_results();
        whalewisdom.HoldingsSearch.stock_match_dlg=
            jQuery( "#stock_match_dlg" ).dialog({modal: true,
                title: 'Multiple Stocks were found',
                height: 340,
                resizable: false,
                show:'slide',
                width: 460 ,
                position: 'center',
                autoOpen: false,
                buttons: [
                {
                    text: "Ok",
                    click: whalewisdom.HoldingsSearch.stock_match_ok
                },
                {
                    text: "Cancel",
                    click: function() {
                        jQuery("#txt_adv_search_stock_symbol").val("");
                        jQuery(this).dialog("close");
                    }
                }
            ]});
        
    },
    bind_listeners:function(){
         jQuery("#txt_adv_search_stock_symbol").change(
            whalewisdom.HoldingsSearch.txt_adv_search_stock_symbol_change);
         jQuery("#stock_search_form input[type='checkbox']").change(
            whalewisdom.HoldingsSearch.checkbox_change);
         jQuery('input:radio[name=rdo_filer_filter]').change(
            whalewisdom.HoldingsSearch.radio_change);
         
        jQuery("#cmd_adv_stock_search").click(
            whalewisdom.HoldingsSearch.search_click);
        jQuery("#cmd_adv_stock_reset").click(
            whalewisdom.HoldingsSearch.reset_click);
        jQuery('#cmd_lookup').click(whalewisdom.HoldingsSearch.show_lookup);
        whalewisdom.HoldingsSearch.accept_numeric_only(jQuery("#txt_mv_from"));
        whalewisdom.HoldingsSearch.accept_numeric_only(jQuery("#txt_mv_to"));
        whalewisdom.HoldingsSearch.accept_numeric_only(jQuery("#txt_holdings_from"));
        whalewisdom.HoldingsSearch.accept_numeric_only(jQuery("#txt_holdings_to"));
        whalewisdom.HoldingsSearch.accept_numeric_only(jQuery("#txt_top_holdings"));

        
    },
    show_lookup:function(){
        whalewisdom.StockLookup.show(jQuery("#txt_adv_search_stock_symbol"));
    },
    refresh_results:function()
    {
        jQuery('#results_table').jqGrid('GridUnload');
        jQuery("#results_table").jqGrid({
                    url:"/search/holdings_search_results?"+whalewisdom.HoldingsSearch.build_parameters(),
                    datatype:"json",
                    colNames:['Filer', 'Shares Held', 'Market Value', 'Ranking within<br/>filer portfolio','Change from<br/>Prior Quarter'],
                    colModel:[
                        {name:'name', index:'name', width:310, formatter:whalewisdom.HoldingsSearch.filer_formatter},
                        {name:'shares', index:'shares', width:100, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                        {name:'mv', index:'mv', width:120, formatter:'currency', formatoptions:{decimalSeparator:".", thousandsSeparator:",", decimalPlaces:0, prefix:"$ "}},
                        {name:'ranking', index:'ranking', width:100, formatter:whalewisdom.HoldingsSearch.ranking_formatter},
                        {name:'position_change_type', index:'position_change_type', width:100, formatter:whalewisdom.HoldingsSearch.format_change}
                    ],
                    rowNum:25,
                    rowList:[10, 25, 50],
                    pager:'#results-pager',
                    sortname:'name',
                    viewrecords:true,
                    sortorder:"asc",
                    loadui:"block",
                    jsonReader:{
                        repeatitems:false,
                        id:"0"
                    },
                    caption:"Holdings",
                    height:'100%'
                });
     /*       whalewisdom.HoldingsSearch.table_columns = [
        {key:"name",label:"Filer", width:400,formatter:whalewisdom.HoldingsSearch.format_description, sortable:true,resizeable:true},
                    {key:"mv",label:"Market Value", sortable:true,resizeable:true, formatter:"currency"},
                    {key:"shares",label:"Shares",sortable:true,resizeable:true,formatter:"number"},
                    {key:"ranking",label:"Ranking within<br/>filer portfolio",sortable:true,resizeable:true,formatter:"number"},
                    {key:"position_change_type",label:"Change from<br/>Prior Quarter", formatter:whalewisdom.HoldingsSearch.format_change,
                        sortable:true,resizeable:true}
    ];

    whalewisdom.HoldingsSearch.table_datasource = new YAHOO.util.DataSource("/search/holdings_search_results?");
    whalewisdom.HoldingsSearch.table_datasource.responseType = YAHOO.util.DataSource.TYPE_JSON;
    whalewisdom.HoldingsSearch.table_datasource.responseSchema = {
                    resultsList:"records",
        fields: ["id","name",{key:"last_13f"},"permalink","security_type","position_change_type",
                            {key:"mv",parser:YAHOO.util.DataSource.parseNumber},
                            {key:"shares",parser:YAHOO.util.DataSource.parseNumber},
                        {key:"ranking",parser:YAHOO.util.DataSource.parseNumber}]//,
                            //metaFields:{totalRecords:"totalRecords"}
    };

            whalewisdom.HoldingsSearch.table_config = {
                width:"35em",
                    paginator : new YAHOO.widget.Paginator({
                            rowsPerPage    : 25,
                                    alwaysVisible : false,
                                    template: "{FirstPageLink} {PreviousPageLink} {NextPageLink} "
                        }) ,
                            initialLoad:false,
                            dynamicData: false,
            generateRequest: whalewisdom.HoldingsSearch.build_parameters,
                                    sortedBy:{key:"name", dir:YAHOO.widget.DataTable.CLASS_ASC}
            };

    whalewisdom.HoldingsSearch.results_table = new YAHOO.widget.DataTable("results_table",
            whalewisdom.HoldingsSearch.table_columns, whalewisdom.HoldingsSearch.table_datasource, whalewisdom.HoldingsSearch.table_config);
            whalewisdom.HoldingsSearch.results_table.showTableMessage("search not yet performed", YAHOO.widget.DataTable.CLASS_LOADING);
    whalewisdom.HoldingsSearch.results_table.subscribe("rowMouseoverEvent", whalewisdom.HoldingsSearch.results_table.onEventHighlightRow);
    whalewisdom.HoldingsSearch.results_table.subscribe("rowMouseoutEvent", whalewisdom.HoldingsSearch.results_table.onEventUnhighlightRow);
            whalewisdom.HoldingsSearch.results_table.subscribe("rowClickEvent", whalewisdom.HoldingsSearch.on_row_click);    */

    },
    ranking_formatter:function (cellvalue, options, record) {
            var cell = record.ranking;
            if (!record.ranking || record.ranking === 999999)
                cell = 'Sold All';
            return cell;
        },
    build_url:function () {
            return '/stock/holdings?id=' + whalewisdom.Stock.stock_id + '&q1=' + jQuery('#quarter_one').val() +
                "&change_filter="+whalewisdom.Stock.build_change_filter()+
                "&" + whalewisdom.Utilities.get_timestamp_cache();
        },
    build_parameters:function(){
       /* var offset="";
        var results="";
        var sortKey="";
        var dir="";
        if (oState){
            offset = (oState.pagination) ? oState.pagination.recordOffset : 0;
            offset = offset != 0 ? (offset - 1) : offset;
            results = (oState.pagination) ? oState.pagination.rowsPerPage : ROWS_PER_PAGE;
            dir = (oState.sortedBy && oState.sortedBy.dir === YAHOO.widget.DataTable.CLASS_DESC) ? "DESC" : "ASC";
	    sortKey   = oState.sortedBy.key;
        }                  */

                var temps=jQuery('#txt_adv_search_stock_symbol').val();
                jQuery('#txt_adv_search_stock_symbol').val('');
                var p=jQuery("#stock_search_form").serialize();
                jQuery('#txt_adv_search_stock_symbol').val(temps);
                p+="&stock_id="+whalewisdom.HoldingsSearch.stock_id;

		return p;
		//return $('frm_option_search').serialize(false)+"&offset="+offset+"&sort="+sortKey+"&dir="+dir+"&results="+results;
	},
	format_description:function(elCell, oRecord, oColumn, sData)
	{
		var desc=sData;
		if (desc && desc.length>50)
	    	elCell.innerHTML = desc.substr(0,50)+"...";
	    else if (desc)
	    	elCell.innerHTML=desc;
		else
			elCell.innerHTML="";
             if (oRecord.getData().security_type!='SH'){
                 elCell.innerHTML=elCell.innerHTML+"<b>("+oRecord.getData().security_type+")</b>";
             }
	},
    filer_formatter:function (cellvalue, options, record) {
            var href = window.location.href.split("/");
            var cell = '<a href="/filer/' + record.permalink + '" title="' + record.name + '">' + record.name;
            if (record.security_type != "SH")
                cell += "<span style='font-weight:bold;'>(" + record.security_type + ")</span>";
            return cell;
        },
        format_change:function(cellvalue, options, record)
	{
            var desc=cellvalue;
            if (desc){
               return cellvalue;
            }
                
            return "";
	},
	on_row_click:function(args)
	{
		var event=args.event;
		var target=args.target;
		var record=whalewisdom.HoldingsSearch.results_table.getRecord(target);
		if (record)
			window.location='/filer/'+record.getData().permalink;
	},
    accept_numeric_only:function(input){
	input.keydown(function(event) {
		// Allow only backspace and delete
		if ( event.keyCode == 46 || event.keyCode == 8 || event.keyCode==9 ||
                    (event.keyCode>95 && event.keyCode<106)) {
			// let it happen, don't do anything
		}
		else {
			// Ensure that it is a number and stop the keypress
			if (event.keyCode < 48 || event.keyCode > 57 ) {
				event.preventDefault();
			}
		}
	});
    },
    reset_parameters:function(){
        var $radios = jQuery('input:radio[name=rdo_filer_filter]');
        $radios.filter('[value=1]').attr('checked', true);
        jQuery("input:checkbox").attr('checked',false);
        jQuery("#txt_stock_symbol").val("");
        whalewisdom.HoldingsSearch.stock_id=0;
        jQuery("#quarter_one").selectedIndex=0;
        jQuery("#txt_mv_from").val("");
        jQuery("#txt_mv_to").val("");
        jQuery("#txt_shares_from").val("");
        jQuery("#txt_shares_to").val("");
        jQuery("#txt_top_holdings").val("");
    },
    search_click:function(){
        //only requirement is that a valid stock has been selected
        if (whalewisdom.HoldingsSearch.stock_id==0){
            whalewisdom.Utilities.show_alert("Missing Information", "You must enter a stock first");
            return;
        }
       if (jQuery('#stock_search_form').disabled)
			return;
        whalewisdom.HoldingsSearch.refresh_results();
       /* var oCallback = {
            success : whalewisdom.HoldingsSearch.search_success,
            failure : whalewisdom.HoldingsSearch.search_failure,
            scope : whalewisdom.HoldingsSearch.results_table,
            argument: whalewisdom.HoldingsSearch.results_table.getState() // data payload that will be returned to the callback function
        };
        var params=whalewisdom.HoldingsSearch.build_parameters(whalewisdom.HoldingsSearch.results_table.getState(),
			whalewisdom.HoldingsSearch.results_table);

        whalewisdom.HoldingsSearch.table_datasource.sendRequest(params, oCallback);
        jQuery('#cmd_adv_stock_search').val('Loading...');
        jQuery('#stock_search_form').attr('disabled',true);
        whalewisdom.Utilities.show_wait_dialog("Searching", "Please wait....Searching for records", false, null);
        
                                                       */
            /*
        $.ajax({
          type: 'POST',
          url: '/search/adv_stock_search_results',
          data: params,
          success: whalewisdom.HoldingsSearch.search_results,
          failure:whalewisdom.HoldingsSearch.search_results_error
        });*/
    },
    reset_click:function(){
        whalewisdom.HoldingsSearch.reset_parameters();
    },
    search_params:function(){
	var p=jQuery("#stock_search_form").serializeArray();
        p.push({name:"stock_id",value:whalewisdom.HoldingsSearch.stock_id})
        jQuery("#results_table").flexOptions({params:p});
	return true;

        //whalewisdom.Utilities.wait_dialog.hide();

        //reload results table
    },
    search_success:function(e1,e2,e3){
        whalewisdom.Utilities.wait_dialog.hide();

        jQuery('#cmd_adv_stock_search').val('Search');
	 jQuery('#stock_search_form').removeAttr('disabled');
		whalewisdom.HoldingsSearch.results_table.onDataReturnInitializeTable(e1,e2,e3);
        
    },
    search_failure:function(e1,e2,e3){
        whalewisdom.Utilities.wait_dialog.hide();
        jQuery('#stock_search_form').removeAttr('disabled');
	jQuery('#cmd_adv_stock_search').val('Search');
	whalewisdom.HoldingsSearch.results_table.onDataReturnInitializeTable(e1,e2,e3);
        
    },
    radio_change:function(){
        var $radios = jQuery('input:radio[name=rdo_filer_filter]');
        if ($radios.filter('[value=1]').attr('checked'))
            jQuery("input:checkbox").attr('checked',false);
    },
    checkbox_change:function(){
        if (jQuery(this).attr('checked'))
        {
            var $radios = jQuery('input:radio[name=rdo_filer_filter]');
            $radios.filter('[value=2]').attr('checked', true);
        }
    },
    txt_adv_search_stock_symbol_change:function(){
        whalewisdom.HoldingsSearch.stock_id=0;
        var data=[];
        if (jQuery("#txt_adv_search_stock_symbol").val()!=""){
            jQuery("#stock_found").text("Looking up stock...");
            jQuery("#stock_found").removeClass();
            jQuery("#stock_found").addClass("looking-up");
            whalewisdom.Utilities.show_wait_dialog("Please wait.", "Looking up stock", false, null);
            jQuery.ajax({
              url: '/search/holdings_search_symbol_change?value='
                +encodeURI(jQuery("#txt_adv_search_stock_symbol").val()),
              dataType: 'json',
              data: data,
              success: function(results){
                if (results.length==0){
                    //display error that no matches found and highlight field
                    jQuery("#stock_found").text("No stock was found with that ticker symbol.");
                    jQuery("#stock_found").removeClass();
                    jQuery("#stock_found").addClass("error");
                }
                else if (results.length>1){
                    //display results table in dialog and ask to select which one
                    jQuery("#stock_found").text("Multiple matches found.  Please choose");
                    jQuery("#stock_found").removeClass();
                    jQuery("#stock_found").addClass("looking-up");
                    //show dialog
                    whalewisdom.HoldingsSearch.show_stock_match_dialog(results);
                }
                else{
                    //exactly one match
                    whalewisdom.HoldingsSearch.stock_id=results[0].id;
                    jQuery("#stock_found").text("Stock found: "+results[0].name);
                    jQuery("#stock_found").removeClass();
                    jQuery("#stock_found").addClass("found");
                }
                whalewisdom.Utilities.wait_dialog.hide();
              },
              error: function(d){
                whalewisdom.Utilities.wait_dialog.hide();
                whalewisdom.Utilities.show_alert("Error finding stock ticker",d);
              }
            });
        }
        else{
            jQuery("#stock_found").text("Please enter a valid stock symbol");
            jQuery("#stock_found").removeClass();
            jQuery("#stock_found").addClass("error");

        }
    },
    show_stock_match_dialog:function(results){
        whalewisdom.HoldingsSearch.stock_match_dlg.
            html("<h1>Please select the stock you were looking for.</h1>"+
                '<table cellpadding="0" cellspacing="0" border="0" class="display" id="stock_match_table">'+
                '<tr><th></th><th>Name</th><th>Symbol</th><th>Is Active</th><th>Delisting Date</th></tr></table>'+
                '<div id=\"stock_match_validation_error\"></div>');
        jQuery('#stock_match_table').data('results', results);
        jQuery('#stock_match_table tr:last').
            after('');
        var result=null;
        for (var i=0;i<results.length;i++){
            result=results[i];
            var row="<tr><td><input type=\"radio\" name=\"rdo_stock_match_selection\" value=\""+i+"\"/></td>"+
                    "<td>"+result.name+"</td><td>"+result.yahoo_ticker_symbol+"</td>"+
                    "<td>"+(result.is_delisted ? "Delisted" : "Active")+"</td>"+
                    "<td>"+(result.delisting_date ? result.delisting_date : "")+"</td></tr>";
            jQuery('#stock_match_table > tbody:last').append(row);
        }
        
        whalewisdom.HoldingsSearch.stock_match_dlg.dialog("open");
    },
    stock_match_ok:function(){
        //validate that at least one radio button selected
        rdos=jQuery('#stock_match_table input');
        var selected_index=-1;
        for (var i=0;i<rdos.length;i++){
            if (rdos[i].checked){
                selected_index=i;
                break;
            }
        }
        if (selected_index>=0){
            
            var data=jQuery("#stock_match_table").data('results');
            var stock=data[selected_index];
            whalewisdom.HoldingsSearch.stock_id=stock.id;
            jQuery("#stock_found").text("Stock found: "+stock.name);
            jQuery("#stock_found").removeClass();
            jQuery("#stock_found").addClass("found");
            jQuery(this).dialog("close");
        }
        else{
            jQuery("#stock_match_validation_error").text("Please select a stock before pressing Ok");
            jQuery("#stock_match_validation_error").addClass("lookup-error");
        }
        
    }
}

jQuery(function () {
    whalewisdom.HoldingsSearch.init();
});