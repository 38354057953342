if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.HeatMap={
    stocks:null,
    first_day_of_period:null,
    last_day_of_period:null,
    quarter:null,
    init:function(){
        if ($('#heat_map').length==0){
            return;
        }
        $('#heatMapStockDialog').modal({
                     show:false
                    });
        window.whalewisdom.HeatMap.build_list();
        window.whalewisdom.HeatMap.build_heat_map();

    },
    build_list:function(){

    },
    build_heat_map:function(){
        var heat_map_id=window.whalewisdom.Utilities.querystring_get("heat_map_id",1);
        var w = $('#heat_map').width() ,
            h = 600,
            x = d3.scale.linear().range([0, w]),
            y = d3.scale.linear().range([0, h]),
            color = d3.scale.category20c(),
            root,
            node;

        var treemap = d3.layout.treemap()
            .round(false)
            .size([w, h])
            .sort(function(a,b) {
                return a.value - b.value;
            })
            .sticky(true)
            .value(function(d) { return d.size; });


        var svg = d3.select("#heat_map").append("div")
            .attr("class", "chart")
            .style("width", w + "px")
            .style("height", h + "px")
            .style("color","#fff")
          .append("svg:svg")
            .attr("width", w)
            .attr("height", h)
          .append("svg:g")
            .attr("transform", "translate(.5,.5)");

        function size(d) {
          return d.size;
        }

        function count(d) {
          return 1;
        }
        function format_text_view(d){

        }

        function zoom(d) {
          var kx = w / d.dx, ky = h / d.dy;
          x.domain([d.x, d.x + d.dx]);
          y.domain([d.y, d.y + d.dy]);

          var t = svg.selectAll("g.cell").transition()
              .duration(d3.event.altKey ? 7500 : 750)
              .attr("transform", function(d) { return "translate(" + x(d.x) + "," + y(d.y) + ")"; });

          t.select("rect")
              .attr("width", function(d) { return kx * d.dx - 1; })
              .attr("height", function(d) { return ky * d.dy - 1; })

          t.select("text")
              .attr("x", function(d) { return kx * d.dx / 2; })
              .attr("y", function(d) { return ky * d.dy / 2; })
              .style("opacity", function(d) { return kx * d.dx > d.w ? 1 : 0; })
              .style("color","#fff")
              .text(format_text_view(d));

          node = d;
          d3.event.stopPropagation();
        }
        function showStockDialog(d){
            $('#heatMapStockTitle').html('<a href="/stock/'+ d.permalink+'">'+d.full_name+' ('+ d.name+')</a>');
            var table='<table class="table"><tbody>'+
                    '<tr><td>Rank</td><td>'+ d.overall_rank+'</td></tr>'+
            '<tr><td>Total Score</td><td>'+ d.score+'</td></tr>'+
            '<tr><td>Previous Rank</td><td>'+ (d.previous_rank ? d.previous_rank : 'N/A') +'</td></tr>'+
            '<tr><td># of filers holding stock</td><td>'+ d.number_of_filers+'</td></tr>'+
            '<tr><td># of times in top 10 holdings</td><td>'+ d.number_of_top_10+'</td></tr>'+
            '<tr><td>Average Portfolio Rank</td><td>'+ d.average_portfolio_ranking+'</td></tr>'+
            '<tr><td># of filers increasing position</td><td>'+ d.number_of_filers_increasing+'</td></tr>'+
            '<tr><td># of filers decreasing position</td><td>'+ d.number_of_filers_decreasing+'</td></tr>'+
                '</tbody></table>';
            $('#heatMapStockBody').html(table);
            $('#heatMapStockDialog').modal('show');
        };


        d3.json("/report/heat_map_data?heat_map_id="+heat_map_id+"&quarter_id="+$('#quarter_id').val()+"&filter="+$('#filter-select').val(), function(data) {
          node = root = data;
            var minScore=0;
            var maxScore=0;
            var stock_list=$('#stock_list');
            stock_list.html('');
            if (data.children.length==0){

                return;
            }
            for (var i=0;i<data.children.length;i++){
                var s=data.children[i];
                if (s.size<minScore)
                minScore=s.size;
                if (s.size>maxScore)
                                maxScore=s.size;
                var list=$('<li><a href="javascript:void(0)">'+s.full_name+' ('+ s.name+')</a></li>');
                list.data('stock',s);
                list.click(function(){
                   showStockDialog($(this).data('stock'));
                });
                stock_list.append(list);
            }

          var nodes = treemap.nodes(root)
              .filter(function(d) { return !d.children; });

          var cell = svg.selectAll("g")
              .data(nodes)
            .enter().append("svg:g")
              .attr("class", "cell")
              .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; })
              .on("click", function(d) { return showStockDialog(d); });

          cell.append("svg:rect")
              .attr("width", function(d) { return d.dx - 1; })
              .attr("height", function(d) { return d.dy - 1; })
              .style("fill", function(d){return whalewisdom.HeatMap.findcolor(d.size, minScore, maxScore);});// { return color(d.parent.name); });

          cell.append("svg:text")
              .attr("x", function(d) { return d.dx / 2; })
              .attr("y", function(d) { return d.dy / 2; })
              .attr("dy", ".35em")
              .attr("fill","#fff")
              .attr("title",function(d){return d.full_name;})
              .attr("text-anchor", "middle")
              .text(function(d) { return d.name; })
              .style("opacity", function(d) { d.w = this.getComputedTextLength(); return d.dx > d.w ? 1 : 0; })
              .style("color","#fff");

       //   d3.select(window).on("click", function() { zoom(root); });

        /*  d3.select("select/*").on("change", function() {
            treemap.value(this.value == "size" ? size : count).nodes(root);
            zoom(node);
          });*/
        });


    },
    position:function() {
      this.style("left", function(d) { return d.x + "px"; })
          .style("top", function(d) { return d.y + "px"; })
          .style("width", function(d) { return Math.max(0, d.dx - 1) + "px"; })
          .style("height", function(d) { return Math.max(0, d.dy - 1) + "px"; });
    },
    findcolor : function(curval, mn, mx) {

        			// value between 1 and 0
        			var position = (curval - mn) / (mx - mn);

        			// this adds 0.5 at the top to get red, and limits the bottom at x= 1.7 to get purple
        			var shft =  0.5*position + 1.7*(1-position);//0.5*position + 1.7*(1-position);//settings.colorStyle == 'roygbiv'
        				//? 0.5*position + 1.7*(1-position)
        				//: position + 0.2 + 5.5*(1-position);

        			// scale will be multiplied by the cos(x) + 1
        			// (value from 0 to 2) so it comes up to a max of 255
        			var scale = 128;

        			// period is 2Pi
        			var period = 2*Math.PI;

        			// x is place along x axis of cosine wave
        			var x = shft + position * period;

        			// shift to negative if greentored
        			x = x;/*settings.colorStyle != 'roygbiv'
        				? -x
        				: x;*/

        			var r = this.process( Math.floor((Math.cos(x) + 1) * scale) );
        			var g = this.process( Math.floor((Math.cos(x+Math.PI/2.0) + 1) * scale) );
        			var b = this.process( Math.floor((Math.cos(x+Math.PI) + 1) * scale) );

        			return '#' + r + g + b;

        		},
                        process: function( num ) {

        			// adjust lightness
        			var n = Math.floor( num +0 * (256 - num));

        			// turn to hex
        			var s = n.toString(16);

        			// if no first char, prepend 0
        			s = s.length == 1 ? '0' + s : s;

        			return s;
        		}
};

jQuery(document).ready(whalewisdom.HeatMap.init);