whalewisdom.Subscription2 = {
    invalid_fields: [],
    coupon_valid: true,
    allow_enterprise: false,
    Elements:null,
    pricing:null,
    gaSubName:'',
    gaPrice:0,
    gaCode:'',
    error_fields: {
        first_name: 'First name',
        last_name: 'Last name',
        number: 'Credit Card number',
        postal_code: 'Postal Code',
        month: 'Expiration Month',
        year: 'Expiration Year',
        cvv: 'CVV',
        state: 'State',
        address1: 'Street',
        city: 'City'
    },
    paypal_error_fields: {
        first_name: 'First name',
        last_name: 'Last name'
    },
    check_valid_coupon: function () {
        var coupon = $.trim($('#coupon').val()).replace(/[\n\r]+/g, '').replace(/[^\x00-\x7F]/g, "");
        var plan_code = $('#plan_code').val()
        if (!coupon || coupon == "") {
            whalewisdom.Subscription2.coupon_valid = true;
            $('#subscriptionForm').formValidation('revalidateField', 'coupon');
        }
        var token = $('meta[name=csrf-token]').attr('content');
        $.ajax({
                method: "POST",
            headers: {
                //  'Content-Type': 'application/json',
                'X-CSRF-Token': token
            },
                url: "/subscription/is_valid_coupon",
                data: {coupon: coupon, plan_code: $('#plan_code').val()}
            })
            .done(function (msg) {
                if (msg.valid)
                    whalewisdom.Subscription2.coupon_valid = true;
                else
                    whalewisdom.Subscription2.coupon_valid = false;
                $('#subscriptionForm').formValidation('revalidateField', 'coupon');
            });
    },

    init: function () {
        if (typeof recurly === 'undefined') {
            return;
        }
        if ($('#subscriptionForm').length==0)
            return;
       // ga('require', 'ecommerce');
        recurly.configure({publicKey:'sc-VLk3iuMQaQDqtzjMo6iLMw',
            number: {
                selector: '#number',
                style: {
                    placeholder: {
                        content: 'Credit Card Number'
                    }
                }
            },
            month: {
                placeholder: 'mm'
            },
            year: {
                placeholder: 'yy'
            },
            cvv: {
                selector: '#cvv',
                style: {
                    placeholder: {
                        content: 'Security Code'
                    }
                }
            }
            });

        const elements = recurly.Elements();
        whalewisdom.Subscription2.Elements=elements;
        const cardElement = elements.CardElement({
            inputType: 'text',
            style: {
                fontSize: '1em',
                placeholder: {
                    color: 'gray !important',
                    fontWeight: 'bold',
                    content: {
                        number: 'Card number',
                        cvv: 'CVV'
                    }
                },
                invalid: {
                    fontColor: 'red'
                }
            }
        });
         /*elements.CardNumberElement().configure({
           style:{
               height:"34px",
               padding: "6px 12px",
               fontSize: "14px",
               color: "#555",
               backgroundColor: "#fff",
               backgroundImage: "none",
               border: "1px solid #ccc",
               borderRadius: "4px",
           }
        });*/
        cardElement.attach('#recurly-elements');
        jQuery('#plan_code').change(function (e) {
           var s = jQuery(this).find(":selected");
           jQuery('#sub_pricing').html(s.data('description'));
        });

        jQuery('#country').change(function (e) {
            var s = jQuery(this).val();
            if (s=="US"){
                jQuery('#state').show();
                jQuery('#state_label').show();
                jQuery('#plan_currency').val('USD')
            }
            else{
                jQuery('#state').hide();
                jQuery('#state_label').hide();
                if (['AT','BE','CY','EE','FI','FR','DE','GR','IE','IT','LV','LT',
                'LU','MT','PT','SK','SI','ES','NL'].includes(s)){
                 //   jQuery('#plan_currency').val('EUR')
                }
                else if (['GB'].includes(s)){
               //     jQuery('#plan_currency').val('GBP')
                }
                else{
                    jQuery('#plan_currency').val('USD')
                }
             //   jQuery('#plan_currency').val('EUR')
            }
        });

        function changeHandler (state) {
            // state.fields
          /*  console.log("number state:"+state.fields.number.valid);
            console.log("month state:"+state.fields.month.valid);
            console.log("year state:"+state.fields.year.valid);
            console.log("cvv state:"+state.fields.cvv.valid);*/

            if (state.fields.number.valid){
                $('#number-error').hide();
            }
            else
                $('#number-error').show();

            if (state.fields.month.valid){
                $('#month-error').hide();
            }
            else
                $('#month-error').show();

            if (state.fields.year.valid){
                $('#year-error').hide();
            }
            else
                $('#year-error').show();

            if (state.fields.cvv.valid){
                $('#cvv-error').hide();
            }
            else
                $('#cvv-error').show();
        }

        recurly.on('change', changeHandler);
        // $('#coupon').change(whalewisdom.Subscription2.check_valid_coupon);
        $('#plan_code').change(whalewisdom.Subscription2.check_valid_coupon);
        var form = $('#subscriptionForm');
        whalewisdom.Subscription2.pricing = recurly.Pricing.Checkout();


        var pricing=whalewisdom.Subscription2.pricing;
        pricing.attach(form);
        // if (console) {
        pricing.on('change', function (price) {
            //   if ($('#coupon').val().length>0)
            //      $(form).formValidation('revalidateField', 'coupon');
            if (console)
                console.info(price);
        //    jQuery('#sub_pricing').html(""+price.now.total);

        });
        pricing.on('error', function (e) {
            if (console)
                console.error(e);
        });
        //      }
        pricing.attach(form);

        whalewisdom.Subscription2.init_validation();

        jQuery('#payby-paypal').click(function (e) {
            jQuery(this).addClass('active');
            jQuery('#payby-credit-card').removeClass('active');
            jQuery('.cc-only').css('display', 'none');
            jQuery('.paypal-only').css('display', '');
        });
        jQuery('#payby-credit-card').click(function (e) {
            jQuery(this).addClass('active');
            jQuery('#payby-paypal').removeClass('active');
            jQuery('.cc-only').css('display', '');
            jQuery('.paypal-only').css('display', 'none');
        });
        $('#coupon').bind('paste', function(event){
            event.preventDefault();
            var clipboardData = event.originalEvent.clipboardData.getData('text/plain');
            console.log(clipboardData);
            var c=$.trim(clipboardData).replace(/[\n\r]+/g, '').replace(/[^\x00-\x7F]/g, "");
            $('#coupon').val(c);
           // whalewisdom.Subscription2.pricing.coupon(c)
            whalewisdom.Subscription2.pricing = recurly.Pricing.Checkout();


            pricing=whalewisdom.Subscription2.pricing;
            pricing.attach(form);
           // whalewisdom.Subscription2.pricing.coupon(c,function(coupon){
          //  });
            $('#subscriptionForm').formValidation('revalidateField', 'coupon');

        });



    },
    submit_form: function () {
        var form = jQuery('#subscriptionForm');
        var coupon = $.trim($('#coupon').val()).replace(/[\n\r]+/g, '').replace(/[^\x00-\x7F]/g, "");
        jQuery('input[type="submit"]').prop('disabled', true);
        jQuery('#subscribing').show();
        if (jQuery('#payby-paypal').hasClass('active')) {
            var s = jQuery('#plan_code').find(":selected");
            recurly.paypal({description: 'Whalewisdom ' + s.text()},
                function (err, token) {
                    if (err) {
                        whalewisdom.Subscription2.error(err);
                    }
                    else {
                        jQuery('#recurly-token').val(token.id);
                        //form.submit();
                        whalewisdom.Subscription2.create_subscription();
                    }
                });
        }
        else {

            recurly.token(whalewisdom.Subscription2.Elements,form, function (err, token) {
                if (err) {
                    whalewisdom.Subscription2.error(err);
                } else {
                    jQuery('#recurly-token').val(token.id);
                    whalewisdom.Subscription2.create_subscription();
                    // form.submit();
                }
            });
        }
    },
    clear_errors: function () {
        while (whalewisdom.Subscription2.invalid_fields.length > 0) {
            whalewisdom.Subscription2.invalid_fields.pop();
        }
        jQuery('.has-error').removeClass('has-error');
    },
    create_subscription: function () {
        var str = jQuery("#subscriptionForm").serialize();

        jQuery.ajax({
            type: "POST",
            url: '/subscription/subscribe',
            data: str,
            dataType: 'json',
            success: whalewisdom.Subscription2.subscription_created,
            error: function (e, st, et) {
                jQuery('input[type="submit"]').prop('disabled', false);
                jQuery('input[type="submit"]').removeClass('disabled');
                jQuery('#subscribing').hide();
                try {
                    if (e.responseText && e.responseText == "Subscription Created")
                        whalewisdom.Subscription2.subscription_created();
                    else if (e.responseJSON)
                        whalewisdom.Subscription2.error(e.responseJSON);
                    else
                        whalewisdom.Subscription2.error(e.responseText);
                }
                catch (e) {
                    whalewisdom.Subscription2.error("An unknown error has occurred.");
                }

            }
        });
    },
    send_to_ga:function(){
      try{
        ga('ecommerce:addTransaction', {
            'id': $('#ujid').val(),                     // Transaction ID. Required.
            'affiliation': $.trim($('#coupon').val()),   // Affiliation or store name.
            'revenue': $('#pmt_total').val(),               // Grand Total.
            'shipping': '0',                  // Shipping.
            'tax': '0'                     // Tax.
        });
        ga('ecommerce:addItem', {
            'id': $('#ujid').val(),                     // Transaction ID. Required.
            'name': $('#plan_code').val(),//'Fluffy Pink Bunnies',    // Product name. Required.
            'sku': $('#plan_code').val(),                 // SKU/code.
            'category': $('#plan_code').val(),         // Category or variation.
            'price': $('#pmt_total').val(),                 // Unit price.
            'quantity': '1'                   // Quantity.
        });
        ga('ecommerce:send');
      }
      catch(ex){

      }

    },
    subscription_created: function (data) {
    //   whalewisdom.Subscription2.send_to_ga();
        window.location = '/subscription/subscribed';
    },

    check_required_fields: function () {
        var fields = jQuery('.form-input__required');

        jQuery.each(fields, function (i, field) {
            var val = jQuery(field).val();
            if (typeof field.selectedIndex != 'undefined') {

            }
            if (val == "") {
                whalewisdom.Subscription2.invalid_fields.push(jQuery(field).attr('data-recurly'));
            }
            else {
                jQuery('.form-input__' + jQuery(field).attr('data-recurly')).removeClass('has-error');
            }
        });
    },
    paypalError: function (err) {

        if (err.niceMessage) {
            errors_markup = '<li class="form-errors--invalid-field">' + err.niceMessage + '</li>';
        } else {
            jQuery.each(err.fields, function (i, field) {
                if (typeof whalewisdom.Subscription2.invalid_fields[field] == undefined) {
                    whalewisdom.Subscription2.invalid_fields.push(field);
                }
            });

            var errors_markup = jQuery.map(whalewisdom.Subscription2.invalid_fields, function (field) {
                jQuery('#' + field).parent().addClass('has-error');
                return '<li class="form-errors--invalid-field">' + whalewisdom.Subscription2.paypal_error_fields[field] || field + '</li>';
            }).join('');
        }

        jQuery('.form-errors').removeClass('form-errors__hidden');
        jQuery('.form-errors ul')
            .empty()
            .append(errors_markup);

        jQuery('input[type="submit"]').prop('disabled', false);
        jQuery('#subscribing').hide();
    },

    // A simple error handling function to expose errors to the customer
    error: function (err) {

        if (err.niceMessage) {
            errors_markup = '<li class="form-errors--invalid-field">' + err.niceMessage + '</li>';
        } else {
            jQuery.each(err.fields, function (i, field) {
                if (jQuery.inArray(field, whalewisdom.Subscription2.invalid_fields) < 0) {
                    whalewisdom.Subscription2.invalid_fields.push(field);
                }
            });

            var errors_markup = jQuery.map(whalewisdom.Subscription2.invalid_fields, function (field) {
                jQuery('#' + field).parent().addClass('has-error');
                return '<li class="form-errors--invalid-field">' + whalewisdom.Subscription2.error_fields[field] || field + '</li>';
            }).join('');
        }

        jQuery('.form-errors').removeClass('form-errors__hidden');

        jQuery('.form-errors ul')
            .empty()
            .append(errors_markup);

        jQuery('input[type="submit"]').prop('disabled', false);
        jQuery('#subscribing').hide();
    },
    init_validation: function () {
        jQuery('#subscriptionForm')
            .formValidation({
                framework: 'bootstrap',
                icon: {
                    valid: 'glyphicon glyphicon-ok',
                    invalid: 'glyphicon glyphicon-remove',
                    validating: 'glyphicon glyphicon-refresh'
                },
                message: 'This value is not valid',
                submitButtons: 'input[type="submit"]',
                fields: {
                    first_name: {
                        group: '#first_div',
                        selector: '#first_name',
                        row: '#first_div',
                        validators: {
                            notEmpty: {
                                message: 'First name is required and cannot be empty'
                            }
                        }
                    },
                    last_name: {
                        selector: '#last_name',
                        group: '#last_div',
                        row: '#last_div',
                        validators: {
                            notEmpty: {
                                message: 'Last name is required and cannot be empty'
                            }
                        }
                    },
                    address1: {
                        selector: '#address1',
                        validators: {
                            notEmpty: {
                                message: 'Street address is required and cannot be empty'
                            }
                        }
                    },
                    address2: {
                        selector: '#address2',
                        validators: {

                        }
                    },
                    /*   state: {
                     group: '#state_div',
                     selector: '#state',
                     validators: {
                     notEmpty: {
                     message: 'State is required'
                     }
                     }
                     },*/
                    country: {
                        selector: '#country',
                        validators: {
                            notEmpty: {
                                message: 'Country is required'
                            }
                        }
                    },
                    city: {
                        selector: '#city',
                        validators: {
                            /**  notEmpty: {
                                    message: 'City is required and cannot be empty'
                                },*/
                            stringLength: {
                                max: 100,
                                message: 'City must be less than 100 characters long'
                            }
                        }
                    },
                    postal_code: {
                        selector: '#postal_code',
                        group: '#zip_div',
                        row: '#zip_div',
                        validators: {
                            notEmpty: {
                                message: 'Postal Code is required and cannot be empty'
                            }/*,
                             regexp: {
                             regexp: /^\d{5}$/,
                             message: 'The US zipcode must contain 5 digits'
                             }*/
                        }
                    },/*
                    number: {
                        selector: '#number',
                        validators: {
                            notEmpty: {
                                message: 'Credit card number is required'
                            },
                            creditCard: {
                                message: 'The credit card number is not valid'
                            },
                            callback: {
                                message: 'Credit card type is not accepted',
                                callback: function (value, validator, $field) {
                                    var ctype = recurly.validate.cardType(value);
                                    return jQuery.inArray(ctype, ['visa', 'master', 'mastercard', 'master', 'discover', 'american_express']) >= 0;//
                                }
                            }
                        }
                    },
                    year: {
                        selector: '#year',
                        validators: {
                            notEmpty: {
                                message: 'Year is required'
                            },
                            regexp: {
                                regexp: '^[0-9]{2,4}$',
                                message: 'Year does not appear to be valid'
                            }
                        }
                    },
                    month: {
                        selector: '#month',
                        validators: {
                            notEmpty: {
                                message: 'Month is required'
                            },
                            regexp: {
                                regexp: '^[0-9]{1,2}$',
                                message: 'Month does not appear to be valid'
                            }
                        }
                    },
                    cvv: {
                        selector: '#cvv',
                        validators: {
                            notEmpty: {
                                message: 'CVV is required'
                            },
                            cvv: {
                                creditCardField: 'number',
                                message: 'The CVV number is not valid'
                            }
                        }
                    },*/
                    coupon: {
                        selector: '#coupon',
                        validators: {
                            remote: {
                                message: 'Coupon code not found for this plan',
                                type: 'POST',
                                url: "/subscription/is_valid_coupon",
                                data: function (validator) {
                                    return {
                                        coupon: $.trim($('#coupon').val()).replace(/[\n\r]+/g, '').replace(/[^\x00-\x7F]/g, ""),
                                        plan_code: $('#plan_code').val()
                                    };
                                }
                                //      data: { coupon: $('#coupon').val(), plan_code: $('#plan_code').val() }
                            }/*,

                             callback: {
                             message: 'Coupon code not found for this plan',
                             callback: function (value, validator, $field) {
                             return whalewisdom.Subscription2.coupon_valid;
                             //  var val=$('#discount_now').html()
                             //   return val.length>0;
                             }
                             }*/
                        }
                    }
                }
            })

            .on('success.form.fv', function (e) {
                // Reset the message element when the form is valid
                jQuery('#errors').html('');
                e.preventDefault();
                whalewisdom.Subscription2.submit_form();
            })

            .on('error.field.fv', function (e, data) {
                // data.bv      --> The BootstrapValidator instance
                // data.field   --> The field name
                // data.element --> The field element

                // Get the messages of field
                var messages = data.fv.getMessages(data.element);

                // Remove the field messages if they're already available
                jQuery('#errors').find('li[data-field="' + data.field + '"]').remove();

                // Loop over the messages
                for (var i in messages) {
                    // Create new 'li' element to show the message
                    jQuery('<li/>')
                        .attr('data-field', data.field)
                        .wrapInner(
                            jQuery('<a/>')
                                .attr('href', 'javascript: void(0);')
                                .html(messages[i])
                                .on('click', function (e) {
                                    // Focus on the invalid field
                                    data.element.focus();
                                })
                        )
                        .appendTo('#errors');
                }

                // Hide the default message
                // $field.data('bv.messages') returns the default element containing the messages
                data.element
                    .data('fv.messages')
                    .find('.help-block[data-bv-for="' + data.field + '"]')
                    .hide();
            })

            .on('success.field.fv', function (e, data) {
                // Remove the field messages
                jQuery('#errors').find('li[data-field="' + data.field + '"]').remove();
            });
    }
}

jQuery(function () {
    whalewisdom.Subscription2.init();
});
