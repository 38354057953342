whalewisdom.StatisticCharts={
    init:function () {
        if ($('#chart_mv').length==0){
            return;
        }
        whalewisdom.StatisticCharts.load_mv_chart('#chart_mv svg',"/statistics/mv_chart_data");
        whalewisdom.StatisticCharts.load_sector_chart("#chart_sectors","/statistics/sector_chart_data");
        whalewisdom.StatisticCharts.load_sector_chart("#chart_sectors_hedge","/statistics/hedge_sector_chart_data");
        whalewisdom.StatisticCharts.load_impact_chart();

    },
    load_mv_chart:function(chart_id, chart_call){
        d3.json(chart_call, function(data) {
            $('#chart_mv .loading').hide();
            var chart = nv.models.lineChart()
                    .margin({left: 75,right: 50})  //Adjust chart margins to give the x-axis some breathing room.
                    .x(function(d) {return d[0];})   //We can modify the data accessor functions...
                    .y(function(d) { return d[1]; })   //...in case your data is formatted differently.
                    .useInteractiveGuideline(true)  //We want nice looking tooltips and a guideline!
                //    .transitionDuration(350)  //how fast do you want the lines to transition?
                    .showLegend(true)       //Show the legend, allowing users to turn on/off line series.
                    .showYAxis(true)        //Show the y-axis
                    .showXAxis(true)        //Show the x-axis
                ;
            chart.xAxis
                .tickFormat(function(d) {
                    var qend=new Date(d);
                    var q=0;
                    if (qend.getMonth()==2)
                        q=1;
                    if (qend.getMonth()==5)
                        q=2;
                    else if (qend.getMonth()==8)
                        q=3;
                    else if (qend.getMonth()==11)
                        q=4;
                    var s="Q"+q+ " "+qend.getFullYear();
                    if (q==0)
                        return "";
                    return s;//d3.time.format('%Y-%m-%d')(new Date(d[0]));
                    //    return d3.time.format('%x')(new Date(d))
                });

            chart.yAxis
                .tickFormat(d3.format('.3s'));

            d3.select(chart_id)    //Select the <svg> element you want to render the chart in.
                .datum(data)         //Populate the <svg> element with chart data...
                .call(chart);          //Finally, render the chart!

            //Update the chart when window resizes.
            nv.utils.windowResize(function() { chart.update() });
            return chart;
        });
    },
    load_sector_chart:function(element,call_url){
        d3.json(call_url, function(data) {
            $(element+' .loading').hide();
            nv.addGraph(function() {
                var chart = nv.models.multiBarChart()//.stackedAreaChart()
                    .margin({right: 25})
                    .x(function(d) {return d[0];})   //We can modify the data accessor functions...
                    .y(function(d) { return d[1]; })   //...in case your data is formatted differently.
                    //  .useInteractiveGuideline(true)    //Tooltips which show all data points. Very nice!
                    .rightAlignYAxis(true)      //Let's move the y-axis to the right side.
                  //  .transitionDuration(500)
                    .stacked(true)
                    //    .showLegend(true)
                    .showYAxis(false)
                    .rotateLabels(0)
                    .groupSpacing(0.1)
                    .reduceXTicks(true)
                    .showControls(false)       //Allow user to choose 'Stacked', 'Stream', 'Expanded' mode.
                    .clipEdge(true);
         //   chart.ticks=10;
                //Format x-axis labels with custom function.
                chart.xAxis
                    .tickFormat(function(d) {
                        var qend=new Date(d);
                        var q=0;
                        if (qend.getMonth()==2)
                            q=1;
                        if (qend.getMonth()==5)
                            q=2;
                        else if (qend.getMonth()==8)
                            q=3;
                        else if (qend.getMonth()==11)
                            q=4;
                        var s="Q"+q+ " "+qend.getFullYear();
                        if (q==0)
                            return "";
                        return s;//d3.time.format('%Y-%m-%d')(new Date(d[0]));
                        //    return d3.time.format('%x')(new Date(d))
                    });

                chart.yAxis
                    .tickFormat(d3.format('.2%'));

                d3.select(element+' svg')
                    .datum(data)
                    .call(chart);

                nv.utils.windowResize(chart.update);

                return chart;
            });
        });
    },
    load_impact_chart:function(){
        d3.json("/statistics/impact_chart_data", function(data) {
            $('#chart_impact .loading').hide();
            var chart = nv.models.lineChart()
                    .margin({left: 100, right:50})  //Adjust chart margins to give the x-axis some breathing room.
                    .x(function(d) {return d[0];})   //We can modify the data accessor functions...
                    .y(function(d) { return d[1]; })   //...in case your data is formatted differently.
                    .useInteractiveGuideline(true)  //We want nice looking tooltips and a guideline!
                    //    .transitionDuration(350)  //how fast do you want the lines to transition?
                    .showLegend(true)       //Show the legend, allowing users to turn on/off line series.
                    .showYAxis(true)        //Show the y-axis
                    .showXAxis(true)        //Show the x-axis
                ;

            chart.xAxis
                .tickFormat(function(d) {
                    var qend=new Date(d);
                    var q=0;
                    if (qend.getMonth()==2)
                        q=1;
                    if (qend.getMonth()==5)
                        q=2;
                    else if (qend.getMonth()==8)
                        q=3;
                    else if (qend.getMonth()==11)
                        q=4;
                    var s="Q"+q+ " "+qend.getFullYear();
                    if (q==0)
                        return "";
                    return s;//d3.time.format('%Y-%m-%d')(new Date(d[0]));
                    //    return d3.time.format('%x')(new Date(d))
                });

            chart.yAxis
                .tickFormat(d3.format(',.0f'));

            d3.select('#chart_impact svg')    //Select the <svg> element you want to render the chart in.
                .datum(data)         //Populate the <svg> element with chart data...
                .call(chart);          //Finally, render the chart!

            //Update the chart when window resizes.
            nv.utils.windowResize(function() { chart.update() });
            return chart;
        });
    }
}
jQuery(function () {
    whalewisdom.StatisticCharts.init();
});