whalewisdom.StockLookup={
    results_table:null,
    lookup_dialog:null,
    field_to_populate:null,
    init:function(){
        //jQuery.getScript('/javascripts/jquery/jquery.fixedheadertable.1.1.2.min.js',
        //    whalewisdom.StockLookup.load);
        whalewisdom.StockLookup.lookup_dialog=jQuery('<div id="stock_lookup_panel""></div>').
            html('<label for="txt_stock_lookup_name">Enter stock name:  </label>'+
            '<span>use * for wildcard</span><br/><input id="txt_stock_lookup_name" type="text"/>'+
            '<div style="width:380px;" id="lookup_autocomplete"></div>').
            dialog({modal:true,autoOpen:false,position: 'center',width:430, height:400,resizable:true,//show:'slide',
            title:"Stock Lookup"});

        jQuery("#txt_stock_lookup_name").autocomplete({
                   minLength:1,
                   source:function(request, response) {
                       jQuery.getJSON("/search/stock_lookup_json",
                           {term: request.term },
                                 response);
                   },
                   select: function( event, ui ) {
                       jQuery("#txt_stock_lookup_name").val(ui.item.name);
                       whalewisdom.StockLookup.field_to_populate.val(ui.item.yahoo_ticker_symbol);
                             if (whalewisdom.HoldingsSearch){
                                 whalewisdom.HoldingsSearch.stock_id=ui.item.id;
                                 jQuery("#stock_found").text("Stock found: "+ui.item.name);
                                 jQuery("#stock_found").removeClass();
                                 jQuery("#stock_found").addClass("found");
                             }
                             whalewisdom.StockLookup.lookup_dialog.dialog('close');
       				return false;
       			}
               }).data( "ui-autocomplete" )._renderItem = function( ul, item ) {
            ul.width(400);
                                var itemDescriptor="";
                                if (item.name.length>40)
                                        itemDescriptor =item.name.substr(0,40)+"...";
                                else
                                    itemDescriptor=item.name;
                                itemDescriptor+= " (" + item.yahoo_ticker_symbol + ")";
                                if (item.is_delisted) {
                                    itemDescriptor+="-delisted ";
                                    if (item.delisting_date)
                                        itemDescriptor+=" on "+item.delisting_date;
                                }
                                if (item.newstock_name){
                                    itemDescriptor+="<br/>Renamed to: ";
                                    if (item.newstock_name.length>40)
                                        itemDescriptor+=item.newstock_name.substr(0,40)+"...";
                                    else
                                        itemDescriptor+=item.newstock_name;
                                    itemDescriptor+= " (" + item.newstock_yahoo_ticker_symbol + ")";
                                    if (item.newstock_is_delisted) {
                                        itemDescriptor+="-delisted ";
                                        if (item.newstockdelisting_date)
                                            itemDescriptor+=" on "+item.delisting_date;
                                    }
                                }
                                return jQuery( "<li class=\"lookup_autocomplete\"></li>" )
            				.data( "item.autocomplete", item )
            				.append( "<a>" + itemDescriptor + "</a>" )
            				.appendTo( ul );
       		};

               jQuery[ "ui" ][ "autocomplete" ].prototype["_renderItem"] = function( ul, item) {
               return jQuery( "<li></li>" )
                 .data( "item.autocomplete", item )
                 .append( jQuery( "<a></a>" ).html( item.label ) )
                 .appendTo( ul );
               };
      /*  jQuery( "#txt_stock_lookup_name" ).autocomplete({
			source: "/search/stock_lookup_json",
			minLength: 2,
                        appendTo:"#lookup_autocomplete",
			focus: function( event, ui ) {
				jQuery( "#txt_stock_lookup_name" ).val( ui.item.name );
				return false;
			},
			select: function( event, ui ) {
                            whalewisdom.StockLookup.field_to_populate.val(ui.item.yahoo_ticker_symbol);
                            if (whalewisdom.HoldingsSearch){
                                whalewisdom.HoldingsSearch.stock_id=ui.item.id;
                                jQuery("#stock_found").text("Stock found: "+ui.item.name);
                                jQuery("#stock_found").removeClass();
                                jQuery("#stock_found").addClass("found");
                            }
                            whalewisdom.StockLookup.lookup_dialog.dialog('close');
				return false;
			}
		}).data( "autocomplete" )._renderItem = function( ul, item ) {
                    ul.width(400);
                    var itemDescriptor="";
                    if (item.name.length>40)
                            itemDescriptor =item.name.substr(0,40)+"...";
                    else
                        itemDescriptor=item.name;
                    itemDescriptor+= " (" + item.yahoo_ticker_symbol + ")";
                    if (item.is_delisted) {
                        itemDescriptor+="-delisted ";
                        if (item.delisting_date)
                            itemDescriptor+=" on "+item.delisting_date;
                    }
                    if (item.newstock_name){
                        itemDescriptor+="<br/>Renamed to: ";
                        if (item.newstock_name.length>40)
                            itemDescriptor+=item.newstock_name.substr(0,40)+"...";
                        else
                            itemDescriptor+=item.newstock_name;
                        itemDescriptor+= " (" + item.newstock_yahoo_ticker_symbol + ")";
                        if (item.newstock_is_delisted) {
                            itemDescriptor+="-delisted ";
                            if (item.newstockdelisting_date)
                                itemDescriptor+=" on "+item.delisting_date;
                        }
                    }
                    return jQuery( "<li class=\"lookup_autocomplete\"></li>" )
				.data( "item.autocomplete", item )
				.append( "<a>" + itemDescriptor + "</a>" )
				.appendTo( ul );
		};*/
        //jQuery('#cmd_stock_lookup_run').click(whalewisdom.StockLookup.search);
    },

    show:function(field){
        whalewisdom.StockLookup.field_to_populate=field;
        jQuery( "#txt_stock_lookup_name").val("");
        jQuery("#txt_stock_lookup_error").html("");
        jQuery("#txt_stock_lookup_error").removeClass();
//        jQuery('#stock_lookup_table').html('<thead class="fixedHeader"><tr><th></th><th>Name</th><th>Symbol</th><th>Is Active</th><th>Delisting Date</th><th>Renamed to:</th></tr></thead><tbody class="scrollContent"></tbody>');
        //jQuery('#stock_lookup_table').hide();
        whalewisdom.StockLookup.lookup_dialog.dialog('open');

    },
    search:function(){
        if (jQuery("#txt_stock_lookup_name").val()==""){
            jQuery("#txt_stock_lookup_error").html("Please enter a name to lookup");
            jQuery("#txt_stock_lookup_error").removeClass();
            jQuery("#txt_stock_lookup_error").addClass("error");
        }
        else if (jQuery("#txt_stock_lookup_name").val().substr(0,1)==="*"){
            jQuery("#txt_stock_lookup_error").html("Name cannot start with wildcard (*)");
            jQuery("#txt_stock_lookup_error").removeClass();
            jQuery("#txt_stock_lookup_error").addClass("error");
        }
        else if (jQuery("#txt_stock_lookup_name").val().replace('*',"")==""){
            jQuery("#txt_stock_lookup_error").html("Please enter a name to lookup");
            jQuery("#txt_stock_lookup_error").removeClass();
            jQuery("#txt_stock_lookup_error").addClass("error");
        }
        else{
            jQuery("#txt_stock_lookup_error").html("");
            jQuery("#txt_stock_lookup_error").removeClass();
            var data=[];
            var oCallback = {
            success : whalewisdom.StockLookup.search_success,
            failure : whalewisdom.StockLookup.search_failure,
            scope : whalewisdom.StockLookup.results_table,
            argument: whalewisdom.StockLookup.results_table.getState() // data payload that will be returned to the callback function
        };
        var params=whalewisdom.StockLookup.build_parameters(whalewisdom.StockLookup.results_table.getState(),
			whalewisdom.StockLookup.results_table);

        whalewisdom.StockLookup.table_datasource.sendRequest(params, oCallback);
        jQuery('#cmd_stock_lookup_search').val('Loading...');
        jQuery('#cmd_stock_lookup_search').attr('disabled','disabled');
       // whalewisdom.Utilities.show_wait_dialog("Searching", "Please wait....Searching for records", false, null);

            /*jQuery('#stock_lookup_table').html('<thead class="fixedHeader"><tr><th></th><th>Name</th><th>Symbol</th><th>Is Active</th><th>Delisting Date</th><th>Renamed to:</th></tr></thead><tbody class="scrollContent"></tbody>');
            var firstButton=jQuery('.ui-dialog-buttonset button:first');
            firstButton.attr("disabled","disabled");
            jQuery.ajax({
                  url: '/search/stock_lookup_json?value='
                    +encodeURI(jQuery("#txt_stock_lookup_name").val()),
                  dataType: 'json',
                  data: data,
                  success: function(results){
                      //display results table
                    var firstButton=jQuery('.ui-dialog-buttonset button:first');
                    firstButton.attr("disabled",'');
                    
                    whalewisdom.StockLookup.show_results(results);
                  },
                  error: function(d){
                      var firstButton=jQuery('.ui-dialog-buttonset button:first');
                    firstButton.attr("disabled",'');
                    whalewisdom.Utilities.wait_dialog.hide();
                    whalewisdom.Utilities.show_alert("Error running stock lookup",d);
                  }
                });*/
            }
    },
    search_success:function(e1,e2,e3){
        //whalewisdom.Utilities.wait_dialog.hide();
        
	jQuery('#cmd_stock_lookup_search').attr('disabled','');
        jQuery('#cmd_stock_lookup_search').innerHTML='Lookup';
	whalewisdom.StockLookup.results_table.onDataReturnInitializeTable(e1,e2,e3);
        
    },
    search_failure:function(e1,e2,e3){
        //whalewisdom.Utilities.wait_dialog.hide();
        jQuery('#cmd_stock_lookup_search').attr('disabled','');
	jQuery('#cmd_stock_lookup_search').innerHTML='Search';
	whalewisdom.StockLookup.results_table.onDataReturnInitializeTable(e1,e2,e3);
        
    },
    refresh_results:function()
    {
            whalewisdom.StockLookup.table_columns = [
        {key:"name",label:"Name", formatter:whalewisdom.StockLookup.format_description, sortable:true,resizeable:true},
                    {key:"yahoo_ticker_symbol",label:"Symbol", sortable:true,resizeable:true},
                    {key:"is_delisted",label:"Is Active",sortable:true,resizeable:true,formatter:whalewisdom.StockLookup.format_active},
                    {key:"delisting_date",label:"Delisting Date",sortable:true,resizeable:true,formatter:"date"},
                    {key:"newstock_name",label:"Renamed to", sortable:true,resizeable:true}
    ];

    whalewisdom.StockLookup.table_datasource = new YAHOO.util.DataSource("/search/stock_lookup_json?");
    whalewisdom.StockLookup.table_datasource.responseType = YAHOO.util.DataSource.TYPE_JSON;
    whalewisdom.StockLookup.table_datasource.responseSchema = {
                    resultsList:"records",
        fields: ["id","name","yahoo_ticker_symbol","permalink","is_delisted","delisting_date",
            "newstock_yahoo_ticker_symbol","newstock_name",
                            {key:"new_stock_id",parser:YAHOO.util.DataSource.parseNumber}]//,
                            //metaFields:{totalRecords:"totalRecords"}
    };
            whalewisdom.StockLookup.table_config = {
                width:"22em",
                    paginator : new YAHOO.widget.Paginator({
                            rowsPerPage    : 5,
                                    alwaysVisible : false,
                                    template: "{FirstPageLink} {PreviousPageLink} {NextPageLink} "
                        }) ,
                            initialLoad:false,
                            dynamicData: false,
            generateRequest: whalewisdom.StockLookup.build_parameters,
                                    sortedBy:{key:"name", dir:YAHOO.widget.DataTable.CLASS_ASC}
            };

    whalewisdom.StockLookup.results_table = new YAHOO.widget.DataTable("stock_lookup_table",
            whalewisdom.StockLookup.table_columns, whalewisdom.StockLookup.table_datasource, whalewisdom.StockLookup.table_config);
            whalewisdom.StockLookup.results_table.showTableMessage("search not yet performed", YAHOO.widget.DataTable.CLASS_LOADING);
    whalewisdom.StockLookup.results_table.subscribe("rowMouseoverEvent", whalewisdom.StockLookup.results_table.onEventHighlightRow);
    whalewisdom.StockLookup.results_table.subscribe("rowMouseoutEvent", whalewisdom.StockLookup.results_table.onEventUnhighlightRow);
            whalewisdom.StockLookup.results_table.subscribe("rowClickEvent", whalewisdom.StockLookup.on_row_click);
    },
    build_parameters:function(oState, oSelf){
		var offset = (oState.pagination) ? oState.pagination.recordOffset : 0;
		offset = offset != 0 ? (offset - 1) : offset;
    	var results = (oState.pagination) ? oState.pagination.rowsPerPage : ROWS_PER_PAGE;
		var dir = (oState.sortedBy && oState.sortedBy.dir === YAHOO.widget.DataTable.CLASS_DESC) ? "DESC" : "ASC";
	    var sortKey   = oState.sortedBy.key;

                var p="&value="+encodeURI(jQuery("#txt_stock_lookup_name").val());
		return p+
			"&sort="+sortKey+"&dir="+dir+"&results="+results;
		//return $('frm_option_search').serialize(false)+"&offset="+offset+"&sort="+sortKey+"&dir="+dir+"&results="+results;
	},
	format_description:function(elCell, oRecord, oColumn, sData)
	{
		var desc=sData;
		if (desc && desc.length>40)
	    	elCell.innerHTML = desc.substr(0,40)+"...";
	    else if (desc)
	    	elCell.innerHTML=desc;
		else
			elCell.innerHTML="";
	},
        format_active:function(elCell, oRecord, oColumn, sData)
	{
		var desc=sData;
                desc = desc ? "Delisted" : "Active";
	    	elCell.innerHTML=desc;
	},
	on_row_click:function(args)
	{
		var event=args.event;
		var target=args.target;
		var record=whalewisdom.StockLookup.results_table.getRecord(target);
		if (record) {
                    var data=jQuery("#stock_lookup_table").data('results');
                whalewisdom.StockLookup.field_to_populate.val(record.getData('yahoo_ticker_symbol'));
                whalewisdom.StockLookup.lookup_dialog.dialog('close');
                }
	}/*
    show_results:function(results){

        jQuery('#stock_lookup_table').data('results', results);

        var result=null;
        for (var i=0;i<results.length;i++){
            result=results[i];
            var row="<tr><td><input type=\"button\"  value=\"Select\"/></td>"+
                    "<td>"+result.name+"</td><td>"+result.yahoo_ticker_symbol+"</td>"+
                    "<td>"+(result.is_delisted ? "Delisted" : "Active")+"</td>"+
                    "<td>"+(result.delisting_date ? result.delisting_date : "")+"</td>"+
                    "<td>"+(result.new_stock_id>0 ? result.newstock_name : "")+"</td></tr>";
            jQuery('#stock_lookup_table > tbody:last').append(row);
            var btn=jQuery('#stock_lookup_table tr:last input');
            btn.data('row_id',i);
        }
        
        jQuery('#stock_lookup_table').show();
        jQuery('#stock_lookup_table input').click(whalewisdom.StockLookup.stock_match_ok);
    },
    stock_match_ok:function(){
        //validate that at least one radio button selected
        rdos=jQuery('#stock_lookup_table input');
        btn=jQuery(this);
        var selected_index=btn.data('row_id');
        /*for (var i=0;i<rdos.length;i++){
            if (rdos[i]===btn){
                selected_index=i;
                break;
            }
        }*/
    /*    if (selected_index>=0){

            var data=jQuery("#stock_lookup_table").data('results');
            var stock=data[selected_index];
            whalewisdom.StockLookup.field_to_populate.val(stock.yahoo_ticker_symbol);
            //jQuery(this).dialog("close");
            whalewisdom.StockLookup.lookup_dialog.dialog('close');
        }
        else{
            jQuery("#stock_match_validation_error").text("Please select a stock before pressing Ok");
            jQuery("#stock_match_validation_error").addClass("lookup-error");
        }

    }*/
};

jQuery(function () {
    whalewisdom.StockLookup.init();
});