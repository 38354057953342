if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.Etfs={
    records:[],
    table:null,
    init:function(){
        if (jQuery("#etf-table").length==0)
            return;
        whalewisdom.Etfs.build_table();
        jQuery('#filter-button').click(whalewisdom.Etfs.refresh);

    },

    build_table:function(){
        $('#etf-table').bootstrapTable({
            url: whalewisdom.Etfs.build_url(),
            sidePagination: 'server',
            pagination:true,
            sortName:'ticker',

            sortOrder:'asc',
            pageSize:25,
            rowStyle:function(row, index) {
                return {
                    css: {"padding": "8px", "height": "37px"}
                };
            },
            //  mobileResponsive:true,
            checkOnInit:true,
            toggle:'table',
            showColumns:true,
            pageList:[10,25,50],
            stickyHeader: true,
            totalField:'records',
            columns: [
                {field:'ticker', title:'Ticker', width:70, formatter:whalewisdom.Etfs.format_ticker,sortable:true},
                {field:'current_filer_count', title:'Filers', width:70,formatter:whalewisdom.Etfs.format_number,sortable:true},
                //{name:'filer_change', index:'filer_change', width:70, formatter:'currency', formatoptions:{decimalPlaces:0, suffix:""}},
                {field:'filer_change_percent', title:'% change<br/>from prior', formatter:whalewisdom.Etfs.format_percent,sortable:true},
                {field:'current_top_10', title:'in Top<br/>10', formatter:whalewisdom.Etfs.format_number,sortable:true},
                //{name:'top10_change', index:'top10_change', width:80, formatter:'currency', formatoptions:{decimalPlaces:0, suffix:""}},
                {field:'percent_top10_change', title:'% change<br/>from prior', formatter:whalewisdom.Etfs.format_percent,sortable:true},
                {field:'increase_decrease_ratio', title:'Increase/Decrease Ratio',  sortable:true},
                {field:'added_position', title:'Increased<br/>position', formatter:whalewisdom.Etfs.format_number,sortable:true},
                {field:'reduced_position', title:'Reduced<br/>Position', formatter:whalewisdom.Etfs.format_number,sortable:true},
                {field:'new_position', title:'New', width:60, formatter:whalewisdom.Etfs.format_number,sortable:true},
                {field:'sold_all_position', title:'Sold Out', formatter:whalewisdom.Etfs.format_number,sortable:true}
            ]
        });

/*
        jQuery("#etf-table").jqGrid({
            url:whalewisdom.Etfs.build_url(),
            datatype:"json",
            colNames:['Ticker','Filers',//'Change<br/>from prior',
                '% change<br/>from prior',
                'in Top<br/>10',
               // 'Change<br/>from prior',
                '% change<br/>from prior',
                'Increased<br/>position',
            'Reduced<br/>Position','new','Sold out'],
            colModel:[
                {name:'ticker', index:'ticker', width:70, formatter:whalewisdom.Etfs.format_ticker},
                {name:'current_filer_count', index:'current_filer_count', width:70, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                //{name:'filer_change', index:'filer_change', width:70, formatter:'currency', formatoptions:{decimalPlaces:0, suffix:""}},
                {name:'filer_change_percent', index:'filer_change_percent', width:70, formatter:'currency', formatoptions:{decimalPlaces:2, suffix:"%"}},
                {name:'current_top_10', index:'current_top_10', width:50, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                //{name:'top10_change', index:'top10_change', width:80, formatter:'currency', formatoptions:{decimalPlaces:0, suffix:""}},
                {name:'percent_top10_change', index:'percent_top10_change', width:70, formatter:'currency', formatoptions:{decimalPlaces:2, suffix:"%"}},
                {name:'added_position', index:'added_position', width:60, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                {name:'reduced_position', index:'reduced_position', width:60, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                {name:'new_position', index:'new_position', width:60, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                {name:'sold_all_position', index:'sold_all_position', width:50, formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}}
            ],
            rowNum:25,
            rowList:[10, 25, 50],
            pager:'#pagination_bottom',
            sortname:'ticker',
            viewrecords:true,
            sortorder:"asc",
            loadui:"block",
            jsonReader:{
                repeatitems:false,
                id:"0"
            },
            caption:"Stock Statistics",
            height:'100%' ,
           autowidth:true
        });*/
    },
    format_ticker:function (cellvalue,  record) {
            var href = window.location.href.split("/");
            var cell = '<a href="/stock/' + record.permalink + '" title="' + record.name + '">' + record.ticker.toUpperCase();
            return cell;
        },
    filter_by_filers:function(str, words, caseSensitive){
        var minlength=0;
        var numfilers=parseInt(str);
        if (words.length==1){
            minlength=parseInt(words[0]);
        }
        return numfilers>minlength;
    },
    format_money:function(cellvalue,record){
        if (!cellvalue || isNaN(cellvalue))
            return "";
        return numbro(cellvalue).formatCurrency({ thousandSeparated: true, mantissa: 0 })///numbro(cellvalue).format('($0,0a');
    },
    format_number:function(cellvalue,record){
        if (!cellvalue ||isNaN(cellvalue))
            return "";
        return numbro(cellvalue).format({ thousandSeparated: true, negative: 'parenthesis' })///numbro(cellvalue).format('(0,0a');
    },
    format_percent:function(cellvalue,record){
        if (!cellvalue ||isNaN(cellvalue))
            return "";
        return numbro(cellvalue).format('0.00')//numbro(cellvalue).format('0.00');
    },
    refresh:function (e) {
        jQuery("#etf-table").bootstrapTable('refresh',{url:whalewisdom.Etfs.build_url()});//jQuery("#etf-table").jqGrid('setGridParam', {url:whalewisdom.Etfs.build_url()}).trigger("reloadGrid");
    },
    build_url:function () {
        return '/statistics/stock_holdings?filer_count=' +  +
            jQuery('#etf-filer-count-filter').val() +
            "&is_etf="+(jQuery('#is-etf').is(':checked') ? "1" : "")+
            "&symbol_filter="+ jQuery('#etf-stock-filter').val()+
            "&" + window.whalewisdom.Utilities.get_timestamp_cache();
    },
    update_query_url:function () {
        jQuery("#etf-table").jqGrid('setGridParam', {url:whalewisdom.Etfs.build_url()});   //.trigger("reloadGrid");
    }
}
jQuery(document).ready(function()
    {
        whalewisdom.Etfs.init();
    }
);