whalewisdom.ShortPositions = {
    init:function () {
        if ($('#frm_short_search').length==0){
            return;
        }
        $('#frm_short_search').submit(function(e){
            e.preventDefault();
            whalewisdom.ShortPositions.refresh();
        });
        $('#frm_aggregate_short_search').submit(function(e){
                    e.preventDefault();
                    whalewisdom.ShortPositions.aggregate_refresh();
                });
        $('.popovers').popover();
        var c=[
                        {field:'position_holder_name', title:'Holder',
                            index:'position_holder_name', sortable:true,
                            formatter:whalewisdom.ShortPositions.holder_formatter},
            {field:'issuer_name',title:'Issuer', index:'issuer_name', sortable:true, formatter:whalewisdom.ShortPositions.issuer_formatter},
                       {field:'net_short_position',title:'Net Short Position', firstsortorder:'desc',index:'net_short_position', sortable:true},
                       {field:'position_date',title:'Position Date', firstsortorder:'desc',index:'position_date', sortable:true},
                        {field:'country',title:'Origin',firstsortorder:'desc', index:'country', sortable:true},
            {field:'is_current',title:'Is Current', index:'is_current', sortable:true, formatter:whalewisdom.ShortPositions.current_formatter}
                    ];
        var names=['Holder','Issuer','Net Short Position','Position Date','Origin', 'Is Current'];
        $('#results_table').bootstrapTable({
            url: whalewisdom.ShortPositions.build_url(),
            sidePagination: 'server',
            pagination:true,
            paginationVAlign: 'both',
            onLoadError:function(arg1,arg2){
                $('#results_table').bootstrapTable('updateFormatText', 'NoMatches', arg2.responseText);
            },
            sortName:'position_date',
            stickyHeader: true,
            sortOrder:'desc',
            showJumpto:true,
            pageSize:25,
            /* rowStyle:function(row, index) {
                 return {
                     css: {"padding": "8px", "height": "37px"}
                 };
             },*/
            // checkOnInit:true,
            // toggle:'table',
            showColumns:true,
            pageList:[10,25,50],
            totalField:'records',
            columns: c
        });

      /*  jQuery("#results_table").jqGrid({
            url:whalewisdom.ShortPositions.build_url(),
            datatype:"json",
            colNames:names,
            colModel:c,
            rowNum:25,
            rowList:[10, 25, 50],
            pager:'#table_pagination_bottom',
            sortname:'position_date',
            firstsortorder:'desc',
            viewrecords:true,
            sortorder:"desc",
            mtype:"GET",
                        prmNames: { nd:null},
            loadui:"block",
            jsonReader:{
                repeatitems:false,
                id:"0"
            },
            caption:"Individual Shorts Transaction History",
            height:'100%',
            autowidth:true,
            width:'95%,'
        });*/

        var c2=[

                    {field:'stock_name',title:'Issuer', index:'stock_name', sortable:true, formatter:whalewisdom.ShortPositions.issuer_formatter},
                               {field:'current_net_shorts',title:'Current', firstsortorder:'desc',index:'current_net_shorts', sortable:true, formatter:whalewisdom.ShortPositions.format_number},
            {field:'net_shorts_change_week', title:'Change 1 Week Ago',firstsortorder:'desc',index:'net_shorts_change_week', sortable:true, formatter:whalewisdom.ShortPositions.format_number},
            {field:'net_shorts_change_month',title:'Change 1 Month Ago', firstsortorder:'desc',index:'net_shorts_change_month', sortable:true,formatter:whalewisdom.ShortPositions.format_number},
            {field:'net_shorts_change_year',title:'Change 1 Year Ago', firstsortorder:'desc',index:'net_shorts_change_year', sortable:true,formatter:whalewisdom.ShortPositions.format_number},
                                {field:'country',title:'Origin', firstsortorder:'desc', index:'country', sortable:true}];
            names=['Issuer','Current','Change 1 Week Ago','Change 1 Month Ago','Change 1 Year Ago','Origin'];

        $('#aggregate_results_table').bootstrapTable({
            url: whalewisdom.ShortPositions.aggregate_build_url(),
            sidePagination: 'server',
            pagination:true,
            paginationVAlign: 'both',
            onLoadError:function(arg1,arg2){
                $('#aggregate_results_table').bootstrapTable('updateFormatText', 'NoMatches', arg2.responseText);
            },
            sortName:'current_net_shorts',
            stickyHeader: true,
            sortOrder:'desc',
            showJumpto:true,
            pageSize:25,
            /* rowStyle:function(row, index) {
                 return {
                     css: {"padding": "8px", "height": "37px"}
                 };
             },*/
            // checkOnInit:true,
            // toggle:'table',
            showColumns:true,
            pageList:[10,25,50],
            totalField:'records',
            columns: c2
        });/*
                jQuery("#aggregate_results_table").jqGrid({
                    url:whalewisdom.ShortPositions.aggregate_build_url(),
                    datatype:"json",
                    colNames:names,
                    colModel:c,
                    rowNum:25,
                    rowList:[10, 25, 50],
                    pager:'#aggregate_table_pagination_bottom',
                    sortname:'current_net_shorts',
                    firstsortorder:'desc',
                    viewrecords:true,
                    sortorder:"desc",
                    mtype:"GET",
                                prmNames: { nd:null},
                    loadui:"block",
                    jsonReader:{
                        repeatitems:false,
                        id:"0"
                    },
                    caption:"Aggregate Short Positions",
                    height:'100%',
                    autowidth:true,
                    width:'95%,'
                });*/

    },
    refresh:function (e) {
        jQuery("#results_table").bootstrapTable('refresh',{url:whalewisdom.ShortPositions.build_url()});
        //jQuery("#results_table").jqGrid('setGridParam', {url:whalewisdom.ShortPositions.build_url()}).trigger("reloadGrid");
    },
    build_url:function () {
        return '/short_position/search_table?'+$('#frm_short_search').serialize();
    },
    aggregate_refresh:function (e) {
        jQuery("#aggregate_results_table").bootstrapTable('refresh',{url:whalewisdom.ShortPositions.aggregate_build_url()});
        //    jQuery("#aggregate_results_table").jqGrid('setGridParam', {url:whalewisdom.ShortPositions.aggregate_build_url()}).trigger("reloadGrid");
        },
        aggregate_build_url:function () {
            return '/short_position/aggregate_search_table?'+$('#frm_aggregate_short_search').serialize();
        },
    current_formatter:function (cellvalue, record) {
            var href = window.location.href.split("/");
            var cell = '';
        if (record.is_current)
            cell='Current'
        else
            cell='Historical';
        return cell;
        },
    format_number:function(cellvalue,record){
        if (!cellvalue ||isNaN(cellvalue))
            return "";
        return numbro(cellvalue).format({
                                            thousandSeparated: true
                                        });
    },

    holder_formatter:function (cellvalue,  record) {
        var href = window.location.href.split("/");
        var cell = '';
        if (record.filer_id)
            cell='<a href="/short_position/holder/' + record.filer_permalink + '" title="' + record.filer_name + '">' + record.filer_name;
        else
            cell='<a href="/short_position/holder/' + record.position_holder_name + '" title="' + record.position_holder_name + '">' + record.position_holder_name;
        return cell;
    },
    issuer_formatter:function (cellvalue, record) {
           var href = window.location.href.split("/");
           var cell = '';
        if (record.stock_id || record.stock_name)
            cell='<a href="/short_position/issuer/' + record.stock_permalink + '" title="' + record.stock_name + '">' + record.stock_name+"</a>";
        else
            cell='<a href="/short_position/issuer/' + record.national_security_identifier + '" title="' + record.issuer_name + '">' + record.issuer_name+"</a>";;

           return cell;
       }
}
jQuery(function () {
    whalewisdom.ShortPositions.init();
});