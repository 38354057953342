if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.Utilities=
{
    get_timestamp_cache:function(){
        return "timestamp="+new Date().getTime();

    },
	show_alert:function(alert_heading,alert_message)
	{
        var alert=jQuery('<div class="modal" style="z-index:11000;"><div class="modal-dialog"> <div class="modal-content"> '+
         ' <div class="modal-header">'+
            '<button type="button" class="close" data-dismiss="modal">×</button>'+
            '<h3>'+alert_heading+'</h3>'+
          '</div>'+
          '<div class="modal-body">'+
            '<p>'+alert_message+'</p>'+
          '</div>'+
          '<div class="modal-footer">'+
           ' <a href="#" class="btn btn-info" data-dismiss="modal">Close</a>'+
          '</div>'+
       ' </div></div></div>').modal();

		/*whalewisdom.Utilities.alert_dialog = new YAHOO.widget.SimpleDialog("alert_dlg", {
			width: "25em",
			effect:{effect:YAHOO.widget.ContainerEffect.FADE,
			        duration:0.25},
			fixedcenter:true,
			modal:true,
		    visible:false,
			close:true,
                        icon: YAHOO.widget.SimpleDialog.ICON_INFO,
			//iframe:true,
			draggable:false,
			constraintoviewport: true,
			buttons: [
	                { text: 'OK', handler: whalewisdom.Utilities.handle_alert_ok, isDefault: true }
	                ]  });
		whalewisdom.Utilities.alert_dialog.setHeader(alert_heading);
		whalewisdom.Utilities.alert_dialog.setBody("<div style=\"text-align:left;\">"+alert_message+"</div>");
		whalewisdom.Utilities.alert_dialog.cfg.setProperty("icon",YAHOO.widget.SimpleDialog.ICON_WARN);
		whalewisdom.Utilities.alert_dialog.render(document.body);
		whalewisdom.Utilities.alert_dialog.show();   */
	},
	show_wait_dialog:function(wait_heading,wait_message, show_cancel_button,cancel_callback)
	{
        if (whalewisdom.Utilities.wait_dialog)
        			whalewisdom.Utilities.wait_dialog.modal('hide');
        var alert_html='<div class="modal" style-="max-width:100px;"> <div class="modal-dialog"> <div class="modal-content">'+
                 ' <div class="modal-header">'+
                    '<h3>'+wait_heading+'</h3>'+
                  '</div>'+
                  '<div class="modal-body">'+
                  //  '<p>'+wait_message+'</p>'+
            '<div style=\"vertical-align:middle;\"><img src="/images/ajax-loader.gif" width="40px"/>&nbsp;'+wait_message+"</div>"+
                  '</div>';
        if (show_cancel_button){
            alert_html+= '<div class="modal-footer">'+
                               '<a href="#" class="btn btn-primary cancel-button" style="color:#ffffff;">Cancel</a>'+
                              '</div>';
        }
        alert_html+="</div></div></div>";

        whalewisdom.Utilities.wait_dialog=jQuery(alert_html);
        whalewisdom.Utilities.wait_dialog.modal({keyboard:false,backdrop:'static'});
        if (cancel_callback){
            jQuery('.cancel-button',whalewisdom.Utilities.wait_dialog).click(cancel_callback);
        }
        whalewisdom.Utilities.wait_dialog.hide=function(){
            whalewisdom.Utilities.wait_dialog.modal('hide');
        }
        /*+
                  '<div class="modal-footer">'+
                   ' <a href="#" class="btn" data-dismiss="modal">Close</a>'+
                  '</div>'+
               ' </div>';    */
		/*if (whalewisdom.Utilities.wait_dialog)
			whalewisdom.Utilities.wait_dialog.destroy();
		if (show_cancel_button)
		{
			whalewisdom.Utilities.wait_dialog = new YAHOO.widget.SimpleDialog("wait_dlg", {
				width: "20em",
				effect:{effect:YAHOO.widget.ContainerEffect.FADE,
				        duration:0.25},
				fixedcenter:true,
				modal:true,
				buttons:[{ text: 'Cancel', handler: cancel_callback, isDefault: false } ],
				close:false,
			    visible:false,
				iframe:true,
				draggable:false,
				constraintoviewport: true });
		}
		else
		{
			whalewisdom.Utilities.wait_dialog = new YAHOO.widget.SimpleDialog("wait_dlg", {
				width: "20em",
				effect:{effect:YAHOO.widget.ContainerEffect.FADE,
				        duration:0.25},
				fixedcenter:true,
				modal:true,
				close:false,
				iframe:true,
			    visible:false,
				draggable:false,
				constraintoviewport: true });
		}
		whalewisdom.Utilities.wait_dialog.setHeader(wait_heading);
		whalewisdom.Utilities.wait_dialog.setBody('<div style=\"vertical-align:middle;\"><img src="/images/ajax-loader.gif" width="40px"/>&nbsp;'+wait_message+"</div>");
		whalewisdom.Utilities.wait_dialog.render(document.body);
		whalewisdom.Utilities.wait_dialog.show();     */
	},
	toggle_wait:function(wait,el)
	{
		if (wait)
		{
			window.document.body.style.cursor="wait";
			//loop through and disable all inputs, textareas and selects
			whalewisdom.Utilities.enable_fields(el,false);

		}
		else
		{
			window.document.body.style.cursor="default";
			whalewisdom.Utilities.enable_fields(el,true);
		}
	},
	enable_fields:function(el,enabled)
	{
		for (var i=0;i<el.childNodes.length;i++)
		{
			var ele=el.childNodes[i];
			if (ele.tagName && (ele.tagName=="INPUT" || ele.tagName=="TEXTAREA" || ele.tagName=="SELECT"))
			{
				ele.disabled=!enabled;
			}
			whalewisdom.Utilities.enable_fields(ele,enabled);
		}
	},
	add_separator:function(val)
	{
		var nStr = val+'';
		var x = nStr.split('.');
		var x1 = x[0];
		var x2 = x.length > 1 ? '.' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
			x1 = x1.replace(rgx, '$1' + ',' + '$2');
		}
		return x1 + x2;
	},
	round_currency:function(num)
	{
		if (!num) return "";
		num = num.toString().replace(/\$|\,/g,'');
		if(isNaN(num))
		num = "0";
		var sign = (num == (num = Math.abs(num)));
		num = Math.floor(num*100+0.50000000001);
		var cents = num%100;
		num = Math.floor(num/100).toString();
		if(cents<10)
		cents = "0" + cents;
		for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
		num = num.substring(0,num.length-(4*i+3))+','+
		num.substring(num.length-(4*i+3));
		var final_val=whalewisdom.Utilities.add_separator((((sign)?'':'-') + '' + num ));//+ '.' + cents));
		if (final_val==0) return "";
		return final_val;
	},
	toggle_wait:function(wait)
	{
		if (wait)
		{
			window.document.body.style.cursor="wait";
			//loop through and disable all inputs, textareas and selects
			whalewisdom.Utilities.enable_fields(document.body,false);

		}
		else
		{
			window.document.body.style.cursor="default";
			whalewisdom.Utilities.enable_fields(document.body,true);
		}
	},
	querystring_get:function(key, default_) 
	{
		var qs;
		var params = new Object()
		
		qs=location.search.substring(1,location.search.length)
	
		if (qs.length == 0) return
		qs=qs.replace(/\+/g, ' ');
		var args = qs.split('&') // parse out name/value pairs separated via &

		for (var i=0;i<args.length;i++) {
			var value;
			var pair = args[i].split('=')
			var name = unescape(pair[0])
	
			if (pair.length == 2)
				value = unescape(pair[1])
			else
				value = name
			
			params[name] = value
		}
		if (default_ == null) default_ = null;
		
		var value=params[key]
		if (value==null) value=default_;
		
		return value
	},
	browser_version:function()
	{
		var browser=navigator.appName;
		var b_version=navigator.appVersion;
		var version=parseFloat(b_version);
		return browser;

	},
        IsNumeric:function(input)
        {
           return (input - 0) == input && input.length > 0;
        },
        post_to_url:function(path, params, method) {
            method = method || "post"; // Set method to post by default, if not specified.

            // The rest of this code assumes you are not using a library.
            // It can be made less wordy if you use one.
            var form = document.createElement("form");
            form.setAttribute("method", method);
            form.setAttribute("action", path);
            var paramarray=params.split("&");
            for (var i=0;i<paramarray.length;i++){
                var keyvaluearray=paramarray[i].split("=")
                if (keyvaluearray.length<2) continue;
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", keyvaluearray[0]);
                hiddenField.setAttribute("value", keyvaluearray[1]);

                form.appendChild(hiddenField);
            }

            document.body.appendChild(form);    // Not entirely sure if this is necessary
            form.submit();
        },
        isLoggedIn:function(){
           var links=jQuery('#logged_in_btn');
            return links.length>0;
        },
        isSubscriber:function(){
            var sub=jQuery('.subscriber');
            return sub.length>0;
        },
    find_in_list:function(list,id,identifier){
		if (!list)
		return null;
        for (var i=0;i<list.length;i++){
            if (list[i][identifier]==id)
                return list[i];
        }
        return null;
    },
    /**
    * Secure Hash Algorithm (SHA1)
    * http://www.webtoolkit.info/
    **/
    SHA1(msg) {
     function rotate_left(n,s) {
     var t4 = ( n<<s ) | (n>>>(32-s));
     return t4;
     };
     function lsb_hex(val) {
     var str='';
     var i;
     var vh;
     var vl;
     for( i=0; i<=6; i+=2 ) {
     vh = (val>>>(i*4+4))&0x0f;
     vl = (val>>>(i*4))&0x0f;
     str += vh.toString(16) + vl.toString(16);
     }
     return str;
     };
     function cvt_hex(val) {
     var str='';
     var i;
     var v;
     for( i=7; i>=0; i-- ) {
     v = (val>>>(i*4))&0x0f;
     str += v.toString(16);
     }
     return str;
     };
     function Utf8Encode(string) {
     string = string.replace(/\r\n/g,'\n');
     var utftext = '';
     for (var n = 0; n < string.length; n++) {
     var c = string.charCodeAt(n);
     if (c < 128) {
     utftext += String.fromCharCode(c);
     }
     else if((c > 127) && (c < 2048)) {
     utftext += String.fromCharCode((c >> 6) | 192);
     utftext += String.fromCharCode((c & 63) | 128);
     }
     else {
     utftext += String.fromCharCode((c >> 12) | 224);
     utftext += String.fromCharCode(((c >> 6) & 63) | 128);
     utftext += String.fromCharCode((c & 63) | 128);
     }
     }
     return utftext;
     };
     var blockstart;
     var i, j;
     var W = new Array(80);
     var H0 = 0x67452301;
     var H1 = 0xEFCDAB89;
     var H2 = 0x98BADCFE;
     var H3 = 0x10325476;
     var H4 = 0xC3D2E1F0;
     var A, B, C, D, E;
     var temp;
     msg = Utf8Encode(msg);
     var msg_len = msg.length;
     var word_array = new Array();
     for( i=0; i<msg_len-3; i+=4 ) {
     j = msg.charCodeAt(i)<<24 | msg.charCodeAt(i+1)<<16 |
     msg.charCodeAt(i+2)<<8 | msg.charCodeAt(i+3);
     word_array.push( j );
     }
     switch( msg_len % 4 ) {
     case 0:
     i = 0x080000000;
     break;
     case 1:
     i = msg.charCodeAt(msg_len-1)<<24 | 0x0800000;
     break;
     case 2:
     i = msg.charCodeAt(msg_len-2)<<24 | msg.charCodeAt(msg_len-1)<<16 | 0x08000;
     break;
     case 3:
     i = msg.charCodeAt(msg_len-3)<<24 | msg.charCodeAt(msg_len-2)<<16 | msg.charCodeAt(msg_len-1)<<8 | 0x80;
     break;
     }
     word_array.push( i );
     while( (word_array.length % 16) != 14 ) word_array.push( 0 );
     word_array.push( msg_len>>>29 );
     word_array.push( (msg_len<<3)&0x0ffffffff );
     for ( blockstart=0; blockstart<word_array.length; blockstart+=16 ) {
     for( i=0; i<16; i++ ) W[i] = word_array[blockstart+i];
     for( i=16; i<=79; i++ ) W[i] = rotate_left(W[i-3] ^ W[i-8] ^ W[i-14] ^ W[i-16], 1);
     A = H0;
     B = H1;
     C = H2;
     D = H3;
     E = H4;
     for( i= 0; i<=19; i++ ) {
     temp = (rotate_left(A,5) + ((B&C) | (~B&D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
     E = D;
     D = C;
     C = rotate_left(B,30);
     B = A;
     A = temp;
     }
     for( i=20; i<=39; i++ ) {
     temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
     E = D;
     D = C;
     C = rotate_left(B,30);
     B = A;
     A = temp;
     }
     for( i=40; i<=59; i++ ) {
     temp = (rotate_left(A,5) + ((B&C) | (B&D) | (C&D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
     E = D;
     D = C;
     C = rotate_left(B,30);
     B = A;
     A = temp;
     }
     for( i=60; i<=79; i++ ) {
     temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
     E = D;
     D = C;
     C = rotate_left(B,30);
     B = A;
     A = temp;
     }
     H0 = (H0 + A) & 0x0ffffffff;
     H1 = (H1 + B) & 0x0ffffffff;
     H2 = (H2 + C) & 0x0ffffffff;
     H3 = (H3 + D) & 0x0ffffffff;
     H4 = (H4 + E) & 0x0ffffffff;
     }
     var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

     return temp.toLowerCase();
    }
}

String.prototype.escapeHTML = function () {                                        
        return(                                                                 
            this.replace(/&/g,'&amp;').                                         
                replace(/>/g,'&gt;').                                           
                replace(/</g,'&lt;').                                           
                replace(/"/g,'&quot;')                                         
        );                                                                      
    };

String.prototype.capitalize = function(){ //v1.0
    return this.replace(/\w+/g, function(a){
        return a.charAt(0).toUpperCase() + a.substr(1).toLowerCase();
    });
};

String.prototype.toINITCAP = function () {
       val = this;
       newVal = '';
       val = val.toLowerCase().split(' ');
       for (var c = 0; c < val.length; c++) {
           newVal += val[c].substring(0, 1).toUpperCase() +
             val[c].substring(1, val[c].length) + ' ';
       }
       return newVal;
   }
/*
String.prototype.startsWith = function(str)
{return (this.match("^"+str)==str)};*/

function IsNumeric(input)
{
   return (input - 0) == input && input.length > 0;
}