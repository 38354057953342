/*HEADER MENU TOGGLE*/
$(document).ready(function(){
 /*-- Animation --*/
  if ( $('.wow').length ){
    initWow(); 
  };  

/*-- on click smooth scroll --*/
$(".drop-link").click(function(event) {
  event.preventDefault();
  $('html,body').animate( { scrollTop:$(this.hash).offset().top } , 1000); } ); 


$('header .dd-selected').click(function(event){
      event.stopPropagation();
       $('header .dd-options').slideToggle('linear', function() {
});

});    

function initWow(){
   // const WOW = require('wowjs');
    var wow = new WOW.WOW( { mobile: false, } );
  wow.init();
} 
});


 
