if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.Contact=
{
	init:function() {
            jQuery('#cmd_contact_submit').click(window.whalewisdom.Contact.on_submit);
	},
        on_submit:function(){
            var msg="";
            if (jQuery('#comments').val()==""){
                jQuery('#contact_validation_message').html("Please enter a comment or message<br/>");
                return false;
            }
            if (jQuery('#captcha').val()==""){
                jQuery('#contact_validation_message').html("Please type the code from the CAPTCHA image<br/>");
                return false;
            }
            jQuery('#contact_validation_message').html(msg);
            jQuery('#frm_contact').submit();
        }
}

jQuery(function(){window.whalewisdom.Contact.init();});