jQuery(document).ready(function(){
    jQuery('ul.whalewisdom-tree li:last-child').addClass('last');
    jQuery('.whalewisdom-tree-toggle').click(function(e){
       var toggle=jQuery(this);
        if (toggle.hasClass("icon-plus-sign")){
            toggle.removeClass("icon-plus-sign");
            toggle.addClass("icon-minus-sign");
            toggle.parent().find("ul").css("display","block");
        }
        else{
            toggle.removeClass("icon-minus-sign");
                        toggle.addClass("icon-plus-sign");
                        toggle.parent().find("ul").css("display","none");
        }
    });
});