window.Sector=
{
	tree_sectors:null,
	stock_table:null,
	sector_array:[],
	init:function()
	{
		/*var loader = new YAHOO.util.YUILoader({
	        //Get these modules
	        require: ['treeview'],
	        allowRollup: true,
                loadOptional:true,
            //    combine:true,
	        onSuccess: function() 
			{*/
		if ($('#tree_sectors').length>0){
            Sector.load_sector_tree();
		}

			/*}});

			loader.insert();*/
		
	},
	load_sector_tree:function()
	{

		Sector.tree_sectors = new YAHOO.widget.TreeView("tree_sectors");
		Sector.populate_sector_tree();
		Sector.tree_sectors.subscribe("labelClick", function(node) 
		{
              if (node.record)
              {
              }
              else if (node.stock)
              {
                    window.location="/stock/"+node.stock.permalink;
              }
        });
		Sector.tree_sectors.draw();
		//see if industry and sector querystring values present
		var qs_industry=whalewisdom.Utilities.querystring_get('industry','');
		var qs_sector=whalewisdom.Utilities.querystring_get('sector','');
		if (qs_industry && qs_sector && qs_industry!='' && qs_sector!='')
		{
			//loop through tree nodes 
			var root_node=Sector.tree_sectors.getRoot();
			var node=Sector.find_node(root_node,qs_industry,qs_sector);
			if (node)
			{
				if (node.parent)
					node.parent.expand();
				node.expand();
			}			
		}
	},
	find_node:function(parent_node,industry,sector)
	{
		var node=null;
		var temp_node=null;
		if (parent_node.record)
		{
			if (parent_node.record.industry==industry && parent_node.record.sector==sector)
				return parent_node;
		}
		for (var i=0;i<parent_node.children.length;i++)
		{
			temp_node=parent_node.children[i];
			node=Sector.find_node(temp_node,industry,sector);
			if (node)
				break;
		}
		return node;
	},
	populate_sector_tree:function()
	{
		//sector arry record stock_count, total_mv, industry, sector
		//var tmpNode = new YAHOO.widget.TextNode("label-" + i, tree.getRoot(), false);
		//new YAHOO.widget.TextNode(node.label + "-" + i, node, false);
		var current_sector='';
		var parent_node=null;
		var child_node=null;
		var record=null;
		for (var i=0;i<Sector.sector_array.length;i++)
		{
			record=Sector.sector_array[i];
			if (record.sector==current_sector)
			{
				child_node=new YAHOO.widget.TextNode(
					record.industry+" - "+record.stock_count+" stocks, "+whalewisdom.Utilities.round_currency(record.percent_change)+"%",
					parent_node, false);
				child_node.record=record;
				child_node.setDynamicLoad(Sector.load_stocks);
			}
			else
			{
				current_sector=record.sector;
				parent_node=new YAHOO.widget.TextNode(record.sector, Sector.tree_sectors.getRoot(), false);
				child_node=new YAHOO.widget.TextNode(
					record.industry+" - "+record.stock_count+" stocks, "+whalewisdom.Utilities.round_currency(record.percent_change)+"% ",
					parent_node, false);
				child_node.record=record;
				child_node.setDynamicLoad(Sector.load_stocks);
			}
		}
	},
	load_stocks:function(node, onCompleteCallback)
	{
		if (node.is_loaded) return;
		var record=node.record;
		Sector.node_to_load=node;
		Sector.onCompleteCallback=onCompleteCallback;
                jQuery.ajax({
                    url:"/sector/retrieve_stocks?sector="+escape(record.sector)+"&industry="+escape(record.industry),
                    success:Sector.load_stock_array_success,
			error:Sector.load_stock_array_failure
                });
		/*new Ajax.Request("/sector/retrieve_stocks?sector="+escape(record.sector)+"&industry="+escape(record.industry),
			{onSuccess:Sector.load_stock_array_success,
			 onFailure:Sector.load_stock_array_failure,asynchronous:true, evalScripts:true});*/
	},
	load_stock_array_success:function(o)
	{
		var data=eval('(' + o + ')');
		var child_node=null;
		var stock=null;
		Sector.stock_array=data;
		var node=Sector.node_to_load;
		for (var i=0;i<Sector.stock_array.length;i++)
		{
			stock=Sector.stock_array[i];
			child_node=new YAHOO.widget.TextNode(
					stock.symbol+": "+stock.name+" - "+whalewisdom.Utilities.round_currency(stock.aggregate_share_count),
					node, false);
			child_node.stock=stock;
		}
		node.is_loaded=true;
		Sector.onCompleteCallback();
	},
	load_stock_array_failure:function(o)
	{
		whalewisdom.Utilities.wait_dialog.hide();
		whalewisdom.Utilities.show_alert('Error',o.responseText);
		Sector.onCompleteCallback();
	}
};
jQuery(function () {
    window.Sector.init();
});