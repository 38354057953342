if (window.whalewisdom === undefined) { window.whalewisdom = {
        required_yui_modules:[],
        add_modules:function(arr){
        }
}; }

window.whalewisdom.Layout=
{
	recently_viewed_container:null,
       // readyEvent:new YAHOO.util.CustomEvent("readyEvent",this),
	init:function()
	{
              try
              {
          //    jQuery.noConflict();
              }
            catch(err)
              {
              //Handle errors here
              }
     //   jQuery('.popover-with-html').popover({ html : true });
        $('#search-toggle-top').click(function(e){
            if ($('#search-navbar').hasClass('in'))
                $('#menu').removeClass('important-hide');
            else
                $('#menu').addClass('important-hide');
        });
        $('.tt').tooltip({});
        if ($('#home_slider').length>0){
            $('#home_slider').unslider({
                        fluid:false,
                        keys:true,
                        delay: 5000,
                        				dots: true
                    });
        }


$('.menu-trigger').click(function(e){
   var b=$(this);
    var menu=$('#menu');
    if (b.hasClass('in')){
        menu.css('height','auto');
        menu.css('')
    }
    else{

    }
});
        var note=$('.note');
        if (note.length>0){
            toastr.info(note.text(), '',
                {
                  "closeButton": true,
                  "debug": false,
                  "newestOnTop": false,
                  "progressBar": false,
                  "positionClass": "toast-bottom-right",
                  "preventDuplicates": false,
                  "onclick": null,
                  "showDuration": "300",
                  "hideDuration": "1000",
                  "timeOut": "6000",
                  "extendedTimeOut": "1000",
                  "showEasing": "swing",
                  "hideEasing": "linear",
                  "showMethod": "fadeIn",
                  "hideMethod": "fadeOut"
                });
        }

       /* toastr.info("<a href=\"/info/hf_letters\" style='text-decoration:underline;'>Click here</a> for more information", "We Need Hedge Fund Letters",
            {
                "closeButton": true,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-bottom-left",
                "preventDuplicates": false,
                "onclick": function(){
                    window.location="/info/hf_letters";
                },
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "16000",
                "extendedTimeOut": "6000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            });*/

        try{jQuery('.dropdown-toggle').dropdown();}
        catch (err){

        }
      /*  $("#close").click(function() {
             $(".note").hide();
           });*/
                jQuery('#cmd_search_history').click(function(){
                                                    jQuery('.user-login-box:visible .recently_viewed_container').modal('show');
                                                });
                   /*var oMenuBar = new YAHOO.widget.MenuBar("whalewisdom_nav_bar", {
                                                autosubmenudisplay: true,
                                                hidedelay: 750,
                                                lazyload: true });

                   oMenuBar.render();
                   YAHOO.util.Dom.removeClass("whalewisdom_nav_bar", "hidden");*/

                  // whalewisdom.Layout.readyEvent.fire([]);
                   if (jQuery('.user-login-box').length>0){
                       jQuery.ajax({
                            type:'GET',
                            url:"/sessions/get_current_session_info",//?"+whalewisdom.Utilities.get_timestamp_cache(),
                            dataType:"html",
                            success:function(data){
                                jQuery('.user-login-box').html(data);
                                var email=null;
                                var subscriber=null;
                                if ($('.user-login-box ')){
                                    email=$('.user-login-box ').find('#logged_in_user_email').val();
                                    if (email && email.trim().length>0 && (typeof InvestingChannelQueue !== 'undefined')){
                                        var hashed=whalewisdom.Utilities.SHA1(email);
                                        InvestingChannelQueue.push(function() {
                                        var ic_page = InvestingChannel.UAT.GetPage();
                                        ic_page.setUserId("email", hashed, true);});
                                    }
                                    subscriber=$('.user-login-box').find('#hslj').val();
                                }

                                /*


                                 */
                                jQuery('.user-login-box:visible .recently_viewed_container').modal({ show:false });
                                $( document ).trigger( "login-box-loaded", [ email,subscriber ] );
                          /*      if (jQuery('#dashboard').length>0){
                                    angular.element('#dashboard').scope().LoggedIn=jQuery('.user-login-box').find('a[href="/users/edit"]').length>0;
                                    angular.element('#dashboard').scope().Subscriber=jQuery('.user-login-box').find('span.subscriber').length>0;
                                    angular.element('#dashboard').scope().Limit=jQuery('.user-login-box').find('#hsli').length>0 ? parseInt(jQuery('.user-login-box').find('#hsli').val()) :10;
                                   // jQuery('#dashboard').scope().LoggedIn=jQuery('.user-login-box').find('a[href="/users/edit"]').length>0;
                                   // jQuery('#dashboard').scope().Subscriber=jQuery('.user-login-box').find('span.subscriber').length>0;
                                }
                                else if (jQuery('#filerdashboard').length>0){
                            angular.element('#filerdashboard').scope().LoggedIn=jQuery('.user-login-box').find('a[href="/users/edit"]').length>0;
                                                                angular.element('#filerdashboard').scope().Subscriber=jQuery('.user-login-box').find('span.subscriber').length>0;
                                                                angular.element('#filerdashboard').scope().Limit=jQuery('.user-login-box').find('#hsli').length>0 ? parseInt(jQuery('.user-login-box').find('#hsli').val()) :10;
                                }*/
                                if (jQuery('#ic_coreg_container').length>0){
                                    jQuery('#ic_coreg_loader').find('#user_email_reg').remove();
                                   jQuery('#ic_coreg_loader').appendTo('#ic_coreg_container');
                                    jQuery('#ic_coreg_loader').css('display','block');
                                    jQuery('#ic_coreg_container').find('table').css('width','');
                                    jQuery('.user-login-box').find('div.control-group').css('margin','0');
                                }
                                var note=$('.noteaj');
                                        if (note.length>0){
                                            toastr.info(note.text(), '',
                                                {
                                                  "closeButton": true,
                                                  "debug": false,
                                                  "newestOnTop": false,
                                                  "progressBar": false,
                                                  "positionClass": "toast-bottom-right",
                                                  "preventDuplicates": false,
                                                  "onclick": null,
                                                  "showDuration": "300",
                                                  "hideDuration": "1000",
                                                  "timeOut": "6000",
                                                  "extendedTimeOut": "1000",
                                                  "showEasing": "swing",
                                                  "hideEasing": "linear",
                                                  "showMethod": "fadeIn",
                                                  "hideMethod": "fadeOut"
                                                })
                                        }

                            },
                            error:function(jqXHR, textStatus, errorThrown){

                            }
                        });
                   }

	}
};
jQuery(function () {
    whalewisdom.Layout.init();
});
// Limit scope pollution from any deprecated API
(function() {

var matched, browser;

// Use of jQuery.browser is frowned upon.
// More details: http://api.jquery.com/jQuery.browser
// jQuery.uaMatch maintained for back-compat
jQuery.uaMatch = function( ua ) {
    ua = ua.toLowerCase();

    var match = /(chrome)[ \/]([\w.]+)/.exec( ua ) ||
        /(webkit)[ \/]([\w.]+)/.exec( ua ) ||
        /(opera)(?:.*version|)[ \/]([\w.]+)/.exec( ua ) ||
        /(msie) ([\w.]+)/.exec( ua ) ||
        ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec( ua ) ||
        [];

    return {
        browser: match[ 1 ] || "",
        version: match[ 2 ] || "0"
    };
};

matched = jQuery.uaMatch( navigator.userAgent );
browser = {};

if ( matched.browser ) {
    browser[ matched.browser ] = true;
    browser.version = matched.version;
}

// Chrome is Webkit, but Webkit is also Safari.
if ( browser.chrome ) {
    browser.webkit = true;
} else if ( browser.webkit ) {
    browser.safari = true;
}

jQuery.browser = browser;
})();
