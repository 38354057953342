window.Filing=
{
	stock_array:null,
	stock_table:null,
	filing_id:null,
	init:function()
	{
		if (jQuery("#stock_table").length==0){
			return;
		}
            Filing.refresh_stock_table();
		/*var loader = new YAHOO.util.YUILoader({
	        //Get these modules
	        require: ['datatable','datasource','dragdrop','paginator'],
	        allowRollup: true,
                loadOptional:true,
                //combine:true,
	        onSuccess: function() 
			{*/
				//Filing.load_stock_array();
			/*}});
		loader.insert();*/
		
	},
	/*load_stock_array:function()
	{
		
		whalewisdom.Utilities.show_wait_dialog('Please Wait','<div>Retrieving latest stock holdings. . .  </div>');
                jQuery.ajax({
                    url:'/filing/filing_stocks/'+Filing.filing_id,
                    success:Filing.load_stock_array_success,
                    error:Filing.load_stock_array_failure
                });
		whalewisdom.Utilities.toggle_wait(true);
	},
	load_stock_array_success:function(o)
	{
		var data=eval('(' + o + ')');
		Filing.stock_array=data;
		whalewisdom.Utilities.toggle_wait(false);
		Filing.refresh_stock_table();
	},
	load_stock_array_failure:function(o)
	{
		whalewisdom.Utilities.toggle_wait(false);
		whalewisdom.Utilities.wait_dialog.hide();
		whalewisdom.Utilities.show_alert('Error',o.responseText);
	},   */
	refresh_stock_table:function()
	{
        jQuery("#stock_table").jqGrid({
                    url:'/filings/filing_stocks/'+Filing.filing_id,
                    datatype:"json",
                    colNames:['Stock', 'Shares Held','Market Value'],
                    colModel:[
                        {name:'name', index:'name',width:"75%", formatter:Filing.format_stock_name},
                        {name:'current_shares', index:'current_shares', width:"25%",formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}},
                        {name:'mv', index:'mv', width:"25%",formatter:'number', formatoptions:{thousandsSeparator:",", decimalPlaces:0}}
                    ],
                    rowNum:25,
                    rowList:[10, 25, 50],
                    pager:'#stock_table_pagination',
                    sortname:'name',
                    viewrecords:true,
                autowidth:true,
                    sortorder:"asc",
            width:"100%",
                    loadui:"block",
                    jsonReader:{
                        repeatitems:false,
                        id:"0",
                        cell:""
                    },
                    caption:"Holdings",
                    height:'100%'
                });
          /*  Filing.stock_table_columns = [
                {key:"name",label:"Stock", formatter:Filing.format_stock_name, sortable:false,resizeable:true},
                            {key:"current_shares",label:"Shares Held",formatter:Filing.format_shares,sortable:false,resizeable:true}
            ];

            Filing.stock_table_datasource = new YAHOO.util.DataSource('/filing/filing_stocks/'+Filing.filing_id);
	    Filing.stock_table_datasource.responseType   = YAHOO.util.DataSource.TYPE_JSON;
            Filing.stock_table_datasource.responseSchema = {
                resultsList : 'records',
                fields: ["id","stock_id","name","current_quarter","permalink","security_type",
                                    {key:"current_shares",parser:YAHOO.util.DataSource.parseNumber},
                                    {key:"percent_change",parser:YAHOO.util.DataSource.parseNumber}],
	        metaFields : {
	            totalRecords: 'totalRecords',
				paginationRecordOffset : "startIndex",
                sortKey: "sort",
                sortDir: "dir"
	        }
            };

        var buildQueryString = function (state,dt) {
            var sortedby=state.sortedBy.dir;

            return "?offset=" + state.pagination.recordOffset +
               "&rows_per_page=" + state.pagination.rowsPerPage+

                           "&dir="+(sortedby == YAHOO.widget.DataTable.CLASS_ASC ? "asc" : "desc")+
                           "&sort="+jQuery('#dt_stock_symbol').val();
        };
        Filing.stock_table_config = {
            dynamicData: true,
                    generateRequest        : buildQueryString,
                initialRequest: "?rows_per_page=25" ,
                                sortedBy:{key:"name", dir:YAHOO.widget.DataTable.CLASS_ASC},
                paginator : new YAHOO.widget.Paginator({
                        rowsPerPage    : 25,
                                alwaysVisible : false
                    }) 
                    
        };

        Filing.stock_table = new YAHOO.widget.DataTable("stock_table",
                Filing.stock_table_columns, Filing.stock_table_datasource, Filing.stock_table_config);

        // Enable row highlighting
        Filing.stock_table.subscribe("rowMouseoverEvent", Filing.stock_table.onEventHighlightRow);
        Filing.stock_table.subscribe("rowMouseoutEvent", Filing.stock_table.onEventUnhighlightRow);
		Filing.stock_table.subscribe("rowClickEvent", Filing.on_row_click);

                Filing.stock_table.handleDataReturnPayload = function(oRequest, oResponse, oPayload) {
	        oPayload.totalRecords = oResponse.meta.totalRecords;
			oPayload.pagination.recordOffset=oResponse.meta.paginationRecordOffset;
			oPayload.sortedBy.key=oResponse.meta.sortKey;
			oPayload.sortedBy.dir=oResponse.meta.sortDir=="asc" ? YAHOO.widget.DataTable.CLASS_ASC : YAHOO.widget.DataTable.CLASS_DESC;
	        return oPayload;
                }
		whalewisdom.Utilities.wait_dialog.hide();   */
	},
	format_stock_name:function(cellvalue, options, record)
	{
		var desc=cellvalue;
		if (desc && desc.length>50)		    
	    	desc = desc.substr(0,50)+"...";
		if (record.security_type!="SH")
			desc+=" <span style='font-weight:bold;'>("+record.security_type+")</span>";
        return desc;
	}//,
	/*format_shares:function(elCell, oRecord, oColumn, sData)
	{
		var desc=sData;
		if (desc && desc!=0 && !isNaN(desc))
			elCell.innerHTML=whalewisdom.Utilities.round_currency(desc);
		else
			elCell.innerHTML="";
	},
	on_row_click:function(args)
	{
		var event=args.event;
		var target=args.target;
		var record=Filing.stock_table.getRecord(target);
		if (record)
			window.location='/stock/'+record.getData().permalink;
	}   */
};
jQuery(function () {
    Filing.init();
});