if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.Whaleindex = {
    init: function () {
       /* if ($('#wi_sector_chart').length>0){
            whalewisdom.Whaleindex.draw_sector_chart();
        }
        if ($('#wi_comparison_chart').length>0){
                    whalewisdom.Whaleindex.draw_perf_chart();
                }*/
        $('#wiSelect').on('change', function (e) {
            var $optionSelected = $("option:selected", this);
            $optionSelected.tab('show')
            var i=$optionSelected.data('tabid');
            whalewisdom.Whaleindex.draw_sector_chart(i);
            whalewisdom.Whaleindex.draw_perf_chart(i);
        });
        for (var i=1;i<38;i++){
            if ($('#wi_sector_chart'+i).length>0){
                        whalewisdom.Whaleindex.draw_sector_chart(i);
                    }
                    if ($('#wi_comparison_chart'+i).length>0){
                                whalewisdom.Whaleindex.draw_perf_chart(i);
                            }
        }

        $('#whaleindex_tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          e.target // newly activated tab
          e.relatedTarget // previous active tab
            var i=$(this).data('tabid');
            whalewisdom.Whaleindex.draw_sector_chart(i);
            whalewisdom.Whaleindex.draw_perf_chart(i);
        })

        $('.wi-email-alert-link').click(function(e){
           ///whaleindex/email_alert?id=<%=@wi.id%>
            var d=$(this).data('indice');
            var that=$(this);
            $.ajax({
              url: "/whaleindex/email_alert?id="+d,
              success: function( data ) {
                              that.parent().after('<div>'+data+'</div>');
                                                              that.remove();

                          },
              dataType: 'text'
            })
        });

        $('.view-new-buys').click(function(e){
            $('.view-portfolio').show();
            $('.view-sold-out').show();
            $('.portfolio-table').find('tr.new-buy').show("slow");
            $('.portfolio-table').find('tr.current-t').hide("slow");
            $('.portfolio-table').find('tr.soldout').hide("slow");
            $('.view-new-buys').hide();
        });

        $('.view-sold-out').click(function(e){
            $('.view-portfolio').show();
            $('.portfolio-table').find('tr.new-buy').hide("slow");
            $('.portfolio-table').find('tr.current-t').hide("slow");
            $('.portfolio-table').find('tr.soldout').show("slow");
            $('.view-sold-out').hide();
            $('.view-new-buys').show();
        });

        $('.view-portfolio').click(function(e){
            $('.view-portfolio').hide();
            $('.view-new-buys').show();
            $('.view-new-buys').show();
            $('.portfolio-table').find('tr.new-buy').show("slow");
            $('.portfolio-table').find('tr.current-t').show("slow");
            $('.portfolio-table').find('tr.soldout').hide("slow");
        });
    },
    draw_sector_chart: function (id) {
            var chart_data = window["ww_chart_data"+id];
        $("#wi_sector_chart"+id+" svg").html('');
            var data=[];
            for (var i = 0; i < chart_data.length; i++) {
                        var row = chart_data[i];
                data.push({label:row["key"],value:parseInt(row["y"])});
                    }
            var chart = nv.models.pieChart()
                  .x(function(d) { return d.label })
                  .y(function(d) { return d.value })
                  .showLabels(true)     //Display pie labels
                  .labelThreshold(.05)  //Configure the minimum slice size for labels to show up
                  .labelType("percent") //Configure what type of data to show in the label. Can be "key", "value" or "percent"
                  .donut(true)          //Turn on Donut mode. Makes pie chart look tasty!
                  .donutRatio(0.35)     //Configure how big you want the donut hole size to be.
                  ;

                d3.select("#wi_sector_chart"+id+" svg")
                    .datum(data)
                    .transition().duration(350)
                    .call(chart);

        },
    draw_perf_chart:function(id){
        var chart_data = window["ww_comp_chart_data"+id];

                       chart_data[0].color="#57aea5";//"#96d8cc";
        chart_data[0].area= true;
        chart_data[0].key="WhaleIndex";
        switch (id){
            case 3:
                chart_data[0].key="Whale 30";
                break;
            case 4:
                            chart_data[0].key="Whale 30 Conservative";
                break;
            case 5:
                            chart_data[0].key="Whale 20";
                break;
            case 6:
                            chart_data[0].key="Whale 20 Conservative";
                break;
            case 8:
                            chart_data[0].key="Whale 30 Mid/Small/Micro Cap";
                            break;
                        case 9:
                                        chart_data[0].key="Whale 30 Mid/Small/Micro Cap Low Turnover";
                            break;
                        case 10:
                                        chart_data[0].key="Whale 20 Mid/Small/Micro Cap";
                            break;
                        case 11:
                                        chart_data[0].key="Whale 30 Mid/Small/Micro Cap Low Turnover";
           case 16:
           chart_data[0].key="Whale 100 - V2.0";
           break;
           case 17:
                      chart_data[0].key="Whale 30 - 2.0";
                      break;
                      case 18:
                                 chart_data[0].key="Whale 20 - 2.0";
                                 break;
         case 19:
                    chart_data[0].key="WhaleIndex 100 Mid/Small/Micro Cap - 2.0";
                    break;
        case 20:
                   chart_data[0].key="Whale 30 Mid/Small/Micro Cap - 2.0";
                   break;
       case 21:
                  chart_data[0].key="Whale 20 Mid/Small/Micro Cap - 2.0";
                            break;
        }
        chart_data[1].color="#111";
        chart_data[1].area= true;

        for (var i = 0; i < chart_data.length; i++) {
                        var p = chart_data[i];
                        var data = [];
                        data=p["values"];
                        //var obj = {key: i == 0 ? "Whale" : p.description, values: [],area: (i==0 ? true : true), color: (i==0 ? '#57aea5' : '#111')}
                       // var compound = 0;

                        for (var j = 0; j < data.length; j++) {
                            var entry = data[j];
                            if (typeof entry["x"] === 'string'){
                                var darray = entry["x"].split("-");
                                var s = new Date(darray[0], parseInt(darray[1])-1, darray[2]);
                                                            entry["x"]=s;
                            }


                        }

                    }

                    var chart = nv.models.lineChart()
                            .margin({left: 100})  //Adjust chart margins to give the x-axis some breathing room.
                            .useInteractiveGuideline(true)  //We want nice looking tooltips and a guideline!
                       //     .transitionDuration(350)  //how fast do you want the lines to transition?
                            .showLegend(true)       //Show the legend, allowing users to turn on/off line series.
                            .showYAxis(true)        //Show the y-axis
                            .showXAxis(true)        //Show the x-axis
                        ;


                    chart.xAxis
                         .tickFormat(function(d) { return d3.time.format('%Y-%m')(new Date(d)) });

                    chart.yAxis     //Chart y-axis settings
                        .axisLabel('Perf')
                        .tickFormat(d3.format('.2%'));

                    d3.select('#wi_comparison_chart'+id+' svg')    //Select the <svg> element you want to render the chart in.
                        .datum(chart_data)         //Populate the <svg> element with chart data...
                        .call(chart);          //Finally, render the chart!

    }
};
jQuery(function () {
    whalewisdom.Whaleindex.init();
});