if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.Schedule13d=
{
	calendar:null,
	min_date:null,
	max_date:null,
	page_date:null,
	current_date:null,
	init:function()
	{
        if ($('#schedule_13ds').length==0){
            return;
        }
             jQuery('.13d_reason').click(Schedule13d.show_13d_reason);
        jQuery('.history-link').click(Schedule13d.show_13d_reason);
        jQuery('.backtest13d-link').click(Schedule13d.backtest);
		/*var loader = new YAHOO.util.YUILoader({
	        //Get these modules
	        require: ['datatable','datasource','dragdrop','calendar'],
	        allowRollup: true,
                loadOptional:true,
             //   combine:true,
	        onSuccess: function() 
			{*/
        /*
				Schedule13d.calendar = new YAHOO.widget.Calendar("calendar","calendar_container", 
																{ pagedate : Schedule13d.page_date,
																  mindate: Schedule13d.min_date,
																  maxdate: Schedule13d.max_date }
																);
        
				Schedule13d.calendar.select(Schedule13d.current_date); 
				Schedule13d.calendar.selectEvent.subscribe(Schedule13d.calendar_select_event,Schedule13d.calendar, true); 
				Schedule13d.calendar.render();*/

			/*}});
		loader.insert();*/
		jQuery('#calendar_container').datepicker({onSelect:function(dateText,inst){
		            var selDate=new Date(dateText);
		            window.location="/schedule13d/index/"+selDate.getFullYear()+"/"+(selDate.getMonth()+1)+"/"+selDate.getDate();
		        }});

      //  jQuery('#calendar_container').datepicker('show');
        jQuery('#calendar_container').datepicker('setDate',Schedule13d.current_date);

	},
	calendar_select_event:function(selDate)
	{
	//	var selected = args[0];
	//	var selDate = this.toDate(selected[0]);
		window.location="/schedule13d/index/"+selDate.getFullYear()+"/"+(selDate.getMonth()+1)+"/"+selDate.getDate();
	},
        show_13d_reason:function(e){
            var a=jQuery(this)[0];
            jQuery.colorbox({href:a.href});
            e.preventDefault();
            return false;
        },
	backtest:function(permalink){
        var field="permalink";
        var val=null;
        if (!permalink || typeof permalink!='string') {
            val = $(this).attr("data-permalink");
            if (!val) {
                val = $(this).attr("data-id");
                field = "id";
            }
        }
        else{
        	val=permalink;
		}
        jQuery.colorbox({href:"/schedule13d/backtester_options?"+field+"="+val,
            onComplete:function(){
                $('#cmd-backtest-13d').click(Schedule13d.run_backtest);
            }});

	},
	run_backtest:function(){
        	var hasAccess=false;
        	var ele=$('#hslj');
        	if (ele.length>0){
        		var id=ele.val();
        		if (parseInt(id)>=2)
        			hasAccess=true;
			}
        if ( !hasAccess) {
            whalewisdom.Utilities.show_alert("Pro Subscription Required",
                "This feature requires a pro subscription to use. <a href='/backtest-13d-sample.xlsx'>Download a sample file.</a>");
            return;
        }
        	var field="permalink";
        	var val=$(this).attr("data-permalink");
        	if (!val){
                val=$(this).attr("data-id");
                field="id";
			}
			var datefrom=$('#backtest-13d-from').val();
        	var dateto=$('#backtest-13d-to').val();
        	var include13f=$('#chk-include-13f').prop('checked');
        	var onebuysell=$('#chk-one-buy').prop('checked');
        jQuery.colorbox({href:"/schedule13d/run_schedule13_backtest?"+field+"="+val+
        "&date_from="+datefrom+"&date_to="+dateto+
        "&include13f="+include13f+"&onebuysell="+onebuysell});


	}
};

jQuery(function () {
    Schedule13d.init();
});
//YAHOO.util.Event.addListener(window, "load",Schedule13d.init);