whalewisdom.filer_stock_history={
    init:function(){
       /* fdTableSort.init('combined_table');
        tablePaginater.init('combined_table');
        jQuery('#results_table_wrapper').css('display', 'block');    */
    }
}

jQuery(function () {
    whalewisdom.filer_stock_history.init();
});