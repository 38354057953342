import "core-js/stable";
import "regenerator-runtime/runtime";
import '../src/common/styles/angular-busy.css'
import '../src/common/styles/animate.min.css'
import '../src/common/styles/generate-chart-styles.scss'
import '../src/common/styles/bootstrap.css'
import '../src/common/styles/bootstrapValidator.css'
import '../src/common/styles/datepicker.css'
import '../src/common/styles/font-awesome.css'
import '../src/common/styles/ivh-treeview-theme-basic.css'
import '../src/common/styles/ivh-treeview.css'
import '../src/common/styles/jquery-ui-1.10.0.custom.scss'
import '../src/common/styles/jquery.multiselect.css'
import "bootstrap-table";
import 'bootstrap-table/dist/bootstrap-table.css';
import "../src/filer/bootstrap-table-mobile.js";
import "../src/filer/bootstrap-table-sticky-header.js"
import "../src/filer/bootstrap-table-sticky-header.css"
import "../src/filer/bootstrap-table-jumpto.css"
import "../src/filer/bootstrap-table-jumpto.js"
//import "../src/filer/bootstrap-table-cookie.js"

/*import '../src/common/styles/ngdialog.css'
import '../src/common/styles/angular-tour.css'
*/
import '../src/common/styles/overrides.css'
import '../src/common/styles/pagination.css'
import '../src/common/styles/select.css'
import '../src/common/styles/select2.css'
import '../src/common/styles/tb.css'
import '../src/common/styles/toastr.css'
import '../src/common/styles/styles.scss'
import '../src/common/styles/backtester_tab.css'
import '../src/common/styles/media.scss'

import '../src/common/third_party/bootstrap.js'
import '../src/common/styles/jquery.mCustomScrollbar.min.scss'

import '../src/common/third_party/custom.js'
import '../src/common/third_party/jquery.ddslick.js'
import '../src/common/third_party/jquery.multiselect.min.js'
import '../src/common/styles/colorbox.scss'
import '../src/common/styles/whalewisdom_styles.scss'

//import 'free-jqgrid'
window.numbro=require('numbro');
//import 'jaaulde-cookies';
//import '../src/common/third_party/nv.d3.js'
import '../src/common/third_party/unslider.js'

window.Cookies=require('js-cookie')


import '../src/common/app/layout.js'
import '../src/common/app/login.js'
import '../src/common/app/search.js'
import '../src/common/app/statistics.js'
import '../src/common/app/utilities.js'
import '../src/common/app/whaleindex.js'
import '../src/common/app/whalewisdom-tree.js'
import '../src/common/app/drip-integration.js'

import '../src/common/app/contact.js';
import '../src/common/app/slack.js';
import '../src/common/app/filer_stock_history.js';
import '../src/common/app/tablepagination.min.js';
import '../src/common/app/short_positions.js'
import '../src/common/app/filing.js'
import '../src/common/app/subscription2.js';
import '../src/common/app/validation/bootstrapValidator.js';
import '../src/common/app/validation/bootstrap.js';

import '../src/common/app/heat_map3.js';
import '../src/common/app/schedule13d.js';
import '../src/common/app/jquery.colorbox-min.js';
import '../src/common/app/ownership.js';
import '../src/common/app/holdings_search.js';
import '../src/common/app/stock_lookup.js';
import '../src/common/app/etfs.js';

import '../src/common/app/jquery.Storage.js';
import '../src/common/app/statistic_charts.js';
import '../src/common/app/refile.js';
import '../src/common/app/hf_letter.js';
import '../src/common/app/sector.js';