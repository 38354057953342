if (window.whalewisdom === undefined) { window.whalewisdom = {
    required_yui_modules:[],
    add_modules:function(arr){
    }
}; }
window.whalewisdom.Slack=
    {
        init:function() {
            $("#frm_slack").submit(function(e) {
                $('#email_val_error').html('');
                $('#email_val_error').hide('');
                $('#contact_validation_message').html('');
                $('#contact_validation_message').hide();
                var url = "/info/slack_request"; // the script where you handle the form input.
                var email=$('#user_email').val();

                if (!email || email.length==0 || !window.whalewisdom.Slack.validateEmail(email)){
                    $('#email_val_error').html('Please enter a valid email address.');
                    $('#email_val_error').show('');
                }
                else if (jQuery('#captcha').val()==""){
                    jQuery('#contact_validation_message').html("Please check for reCAPTCHA<br/>");
                    $('#contact_validation_message').show();
                }
                else {
                    $('#btn btn-primary').val('submitting');
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: $("#frm_slack").serialize(), // serializes the form's elements.
                        success: function (data) {
                            if (data=="Success"){
                                $('#slack_request_received').show();
                                $('#frm_slack').hide();
                            }
                            else{
                                $('#contact_validation_message').html(data);
                                $('#contact_validation_message').show();
                                $('#btn btn-primary').val('Join');
                            }

                        },
                        error:function(d){

                        }
                    });
                }
                e.preventDefault(); // avoid to execute the actual submit of the form.
            });
        },
        validateEmail:function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }

jQuery(function(){window.whalewisdom.Slack.init();});